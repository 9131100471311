import { createApp, h, Fragment, computed, type App } from 'vue'
import VueTippy from 'vue-tippy'
import VueAxe, { VueAxePopup } from 'vue-axe'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { attachErrorHandler } from '@sentry/vue'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/border.css'
import 'tippy.js/themes/light.css'
import 'v3-infinite-loading/lib/style.css'
import 'v-calendar/dist/style.css'
import 'core-js/modules/es.array.to-sorted'

import './utils/scrollbar'
import { initSentry } from './sentry'
import { loadLanguageAsync, getSessionLocale, i18nInst } from './i18n'
import config, { embeddedRuntime, hasZendeskProxy } from './config'
import { loadSession, session } from './composables/useSession'
import { getExtensionOrigin, illegallyEmbedded, setExtensionOrigin } from './modules/auth/utils/extension'
import { registerWithStoredInviteToken } from './modules/auth/utils/invite-token'

import Application from './modules/application/Application.vue'
import DummyApplication from './modules/application/DummyApplication.vue'
import { popperOptions } from './components/Tippy'
import { sanitizeHook } from './utils/directives/sanitize.directive'
import klausEasterEgg from './utils/klausEasterEgg'
import { loadTicketsExist } from './composables/useTicketsExist'
import { loadZ2SunCoWidget } from './z2-sunco-widget'

initSentry()

const isProxied = computed(() => hasZendeskProxy || session.features.zendeskProxyDev)

const createDummyApp = async (locale: string) => {
  await loadLanguageAsync(locale)
  const app = createApp(DummyApplication)
  app.use(i18nInst)
  app.mount('#app')
  return app
}

// App setup
;(async () => {
  const initialLocale = getSessionLocale()
  const dummyApp = await createDummyApp(initialLocale)

  if (illegallyEmbedded) {
    window.location.href = 'https://www.klausapp.com'
    return
  }

  // Redirect to custom extension origin aka Zendesk domain if set
  // Also reset the custom origin on login page to avoid infinite redirect loop
  if (embeddedRuntime()) {
    if (location.pathname.startsWith('/login')) {
      setExtensionOrigin(null)
    } else {
      const customOrigin = getExtensionOrigin()
      if (customOrigin) {
        location.replace(customOrigin)
        return
      }
    }
  }

  // Gorgias passes an account param automatically
  // Remove it so that it doesn't get passed to our session endpoint and cause an error
  if (window.location.pathname === '/settings/connections/install/gorgias') {
    const url = new URL(window.location.href)
    url.searchParams.delete('account')
    history.replaceState(null, '', url.toString())
  }
  // Try to load auth session
  const session = await loadSession()

  // Sign up user if we have stored invite token
  if (session?.isAuthenticated && !hasZendeskProxy && (await registerWithStoredInviteToken())) return
  if (session.redirectToZendesk) return

  const sessionLocale = getSessionLocale(session)

  if (sessionLocale !== initialLocale) {
    await loadLanguageAsync(sessionLocale)
  }

  // Check if the ZD account has sufficient tickets to show navigation pages
  if (isProxied.value && session?.isAuthenticated && session.account) await loadTicketsExist()

  let app: App<Element> | null = null

  if (config.checkA11y) {
    app = createApp({
      setup() {
        return () => h(Fragment, [h(Application), h(VueAxePopup)])
      },
    })
    app.use(VueAxe, {
      config: {
        rules: [
          // define rules: https://github.com/dequelabs/axe-core/blob/master/doc/rule-descriptions.md
        ],
        allowedOrigins: [],
      },
      runOptions: {
        runOnly: {
          type: 'tag',
          values: ['wcag2a'],
        },
      },
    })
  } else {
    app = createApp(Application)
  }

  loadZ2SunCoWidget()

  attachErrorHandler(app, { attachProps: true, logErrors: true } as any)

  app.use(i18nInst)

  app.use(VueQueryPlugin, {
    // navigator.onLine has some bugs on Macs and useQuery relies on it, so we set a different networkMode to fire off at least 1
    // request, even if the user's browser claims to be offline: https://github.com/TanStack/query/issues/5679#issuecomment-1623038531
    queryClientConfig: { defaultOptions: { queries: { refetchOnWindowFocus: false, networkMode: 'offlineFirst' } } },
  })

  app.use(VueTippy, {
    popperOptions,
    onShow: ({ props }) => !!props.content || !!props.title,
  })

  const { generateRouter } = await import('./router')
  const router = generateRouter()
  app.use(router)

  app.directive('sanitize', sanitizeHook)

  dummyApp.unmount()
  app.mount('#app')

  console.info(klausEasterEgg)
  console.info(`🐾 🐾 ${embeddedRuntime() ? 'ZQA extension loaded' : 'Welcome to ZQA'} ${config.gitCommit}`)
})()
