import { type AccountRole, type WorkspaceRole, ACCOUNT_ROLE_OPTIONS, WORKSPACE_ROLE_OPTIONS } from '@/data/roles'
import i18n from '@/i18n'
import { hasAccountRole, hasHighestWorkspaceRole } from '@/utils/roleUtils'
import { removeDiacritics } from '@/utils/diacritics'
import { session } from '@/composables/useSession'
import { UserStateFilter, UserStatus, type AccountUser, type User, type WorkspaceUser } from './types'

export const toArray = <D>(objectOrList: D | D[]) => (Array.isArray(objectOrList) ? objectOrList : [objectOrList])

/**
 * Is user selected
 */
export const isSelected = (selection: string[], id: string) => selection.includes(id)

/**
 * Toggle user selection
 * Supports bulk modifier to select a range of ID-s
 */
export const toggleSelection = (selection: string[], users: any[], id: string, isSelected: boolean, bulk: boolean) => {
  if (bulk) {
    const key = users.some((u) => u.id) ? 'id' : 'email'
    const lastId = selection[selection.length - 1]
    let fromIdx = users.findIndex((u) => u[key] === lastId)
    let toIdx = users.findIndex((u) => u[key] === id)
    if (fromIdx > toIdx) [fromIdx, toIdx] = [toIdx, fromIdx]
    const newIds = users
      .slice(fromIdx, toIdx)
      .map((u) => u[key])
      .concat([id])

    if (isSelected) return [...selection, ...newIds]

    return selection.filter((u) => !newIds.includes(u))
  }

  if (isSelected) {
    const newSelection = [...selection, id]
    return newSelection
  } else {
    return selection.filter((i) => i !== id)
  }
}

interface BaseUser {
  name: string
  email: string
}
/**
 * Filter users by email or name partially
 */
export const fuzzyUserFilter = <T extends BaseUser>(users: T[], rawFilter: string | string[]) => {
  const filter =
    typeof rawFilter === 'string'
      ? removeDiacritics(rawFilter).toLowerCase()
      : rawFilter.map((f) => removeDiacritics(f).toLowerCase())

  return users.filter((u) => {
    const name = removeDiacritics(u.name).toLowerCase()
    const email = u.email.toLowerCase()

    if (typeof filter === 'string') {
      return name.indexOf(filter) > -1 || email.indexOf(filter) > -1
    } else {
      return filter.some((f) => name.indexOf(f) > -1 || email.indexOf(f) > -1)
    }
  })
}

export const getAccountRoleOptions = () => {
  let options = ACCOUNT_ROLE_OPTIONS
  if (!hasAccountRole('ADMIN')) options = options.filter((r) => r !== 'ADMIN')
  if (hasAccountRole('USER')) options = options.filter((r) => r === 'USER')
  return options.map((r) => ({ label: getAccountRoleLabel(r), value: r }))
}

export const getWorkspaceRoleOptions = () =>
  WORKSPACE_ROLE_OPTIONS.map((r) => ({ label: getWorkspaceRoleLabel(r), value: r }))

export const getAccountRoleLabel = (role: AccountRole) => {
  if (!role) return i18n.t('universal.role_none')
  return i18n.t(`universal.account_role.${role.toLowerCase()}`)
}

export const getWorkspaceRoleLabel = (role?: WorkspaceRole) => {
  if (!role) return i18n.t('universal.role_none')
  return i18n.t(`universal.workspace_role.${role.toLowerCase()}`)
}

/**
 * User is readonly when it is imported from SCIM & there is no override flag in place
 */
export const isUserReadonly = (u: User) => u.readonly && !session.features.editableSCIMUsers

/**
 * User can be edited - removed and role changed if they are not the owner, logged in user, readonly or user with higher role like admin
 */
export const isUserEditable = (u: User) => {
  const isCurrentUser = parseInt(u.id) === session.user.id

  let canRemove = false
  if (hasAccountRole('ADMIN')) {
    canRemove = true
  } else if (hasAccountRole('MANAGER')) {
    // Can remove everybody except admins
    canRemove = u.accountRole !== 'ADMIN'
  } else if (hasHighestWorkspaceRole('MANAGER')) {
    // Allow removing non-admins for wsManagerCanInvite flag
    canRemove = !u.accountRole || u.accountRole === 'USER'
  }
  return !isCurrentUser && !u.owner && canRemove && !isUserReadonly(u)
}

/**
 * User is active when they have a role
 */
export const isUserActive = (u: User) => u.status === UserStatus.Active

/**
 * User is connected when they don't have a pending invite nor a role
 */
export const isUserConnected = (u: User) => u.status === UserStatus.Connected

/**
 * User is pending when they have a pending invite role
 */
export const isUserPending = (u: User) => u.status === UserStatus.Pending

/**
 * Users who need action - non-admin active or invited users who are not part of any workspace
 */
export const userNeedsAction = (u: AccountUser | WorkspaceUser) =>
  'workspaces' in u && u.workspaces.length === 0 && (u.accountRole === 'USER' || u.pendingInviteAccountRole === 'USER')

/**
 * User who can be marked as bot.
 */
export const canMarkAsBot = ({ accountRole, pendingInviteAccountRole, hasMultipleAccounts }: User) =>
  accountRole !== 'ADMIN' &&
  accountRole !== 'MANAGER' &&
  pendingInviteAccountRole !== 'MANAGER' &&
  pendingInviteAccountRole !== 'ADMIN' &&
  !hasMultipleAccounts

/**
 * Filter users by status
 */
export const filterUsersByStatus = <T extends AccountUser | WorkspaceUser>(users: T[], status: UserStateFilter) => {
  if (status === UserStateFilter.All) {
    return users
  } else if (status === UserStateFilter.Active) {
    return users.filter(isUserActive)
  } else if (status === UserStateFilter.Connected) {
    return users.filter(isUserConnected)
  } else if (status === UserStateFilter.Pending) {
    return users.filter(isUserPending)
  } else if (status === UserStateFilter.ActionNeeded || status === UserStateFilter.NoPermissions) {
    return users.filter(userNeedsAction)
  } else if (status === UserStateFilter.ScimSetup) {
    return users.filter((u) => u.readonly)
  } else if (status === UserStateFilter.ManualSetup) {
    return users.filter((u) => !u.readonly)
  } else if (status === UserStateFilter.Admin) {
    return users.filter((u) => u.accountRole === 'ADMIN')
  } else if (status === UserStateFilter.User) {
    return users.filter((u) => u.accountRole === 'USER')
  } else {
    return users
  }
}

export const showUsersNav = () => hasAccountRole('ADMIN', 'MANAGER') || hasHighestWorkspaceRole('MANAGER')

export const showGroupsNav = () => hasAccountRole('ADMIN', 'MANAGER') || hasHighestWorkspaceRole('MANAGER', 'LEAD')

export const showBotsNav = () =>
  session.features.botQa && (hasAccountRole('ADMIN', 'MANAGER') || hasHighestWorkspaceRole('MANAGER'))
