import { FilterTimeStep, FilterDirection, FilterSelfReview, FilterTimePeriod, type FilterParams } from '../types'
import { DisplayDateSetting, type CsatFilterParams, CsatOwnerSetting } from '../csat/types'
import { getDatesFromPeriod } from './period-options'

export const defaultTimePeriod = FilterTimePeriod.Week // Last 7 days
export const defaultFilterDirection = FilterDirection.Received

const mainDashboardDefaultParams = {
  decimals: 0,
  workspaceIds: [],
  timePeriod: defaultTimePeriod,
  userGroups: [],
  userIds: [],
  connectionIds: [],
  ratingCategoryTags: [],
  commentTags: [],
  ticketTags: [],
  helpdesksCustomFilters: [],
  ticketDates: false,
  step: FilterTimeStep.Day,
  direction: defaultFilterDirection,
  selfReview: FilterSelfReview.Exclude,
}

const csatDashboardDefaultParams: CsatFilterParams = {
  workspaceIds: [],
  timePeriod: defaultTimePeriod,
  userGroups: [],
  userIds: [],
  connectionIds: [],
  ticketTags: [],
  csatReasons: [],
  wordCloudWords: [],
  predictedDimensions: [],
  predictedTags: [],
  helpdesksCustomFilters: [],
  step: FilterTimeStep.Day,
  rawCsatScores: [],
  csatOwnerSetting: CsatOwnerSetting.ticketAssignee,
  displayDateSetting: DisplayDateSetting.ticketCreated,
  questionTypeId: null,
  surveyIds: [],
  languages: [],
}

export const getDefaultParams = (
  activeRouteName: string,
  extraParams?: Partial<FilterParams>,
): FilterParams | CsatFilterParams => {
  const { fromDate, toDate } = getDatesFromPeriod({ timePeriod: defaultTimePeriod })
  return {
    ...(activeRouteName === 'dashboard.surveys' ? csatDashboardDefaultParams : mainDashboardDefaultParams),
    fromDate,
    toDate,
    categoryId: undefined,
    channels: [],
    ...extraParams,
  }
}
