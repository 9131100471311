import { type AsyncComponentLoader } from 'vue'

import logos from './logos'
import icons from './icons'

interface Theme {
  name: string
  icons: {
    dashboard: any
    conversations: any
    activity: any
    coaching: any
    settings: any
    tasks: any
    users: any
    help: any
    productTray: any
  }
  logos: Record<string, AsyncComponentLoader>
}

const theme: Theme = { name: 'zendesk', icons, logos }

export default theme
