/** @type {import('tailwindcss').Config} */
// Base Tailwind styles
// https://github.com/tailwindlabs/tailwindcss/blob/master/stubs/defaultConfig.stub.js

module.exports = {
  content: [
    './**/*.{vue,js,ts,jsx,tsx}',
    './packages/ui-kit/**/*.{vue,js,ts,jsx,tsx}',
    '../app/**/*.{vue,js,ts,jsx,tsx}',
  ],
  theme: {
    fontFamily: {
      default: 'var(--font-family)',
      mono: [
        'ui-monospace',
        'SFMono-Regular',
        'Menlo',
        'Monaco',
        'Consolas',
        'Liberation Mono',
        'Courier New',
        'monospace',
      ],
    },
    boxShadow: {
      flat: '0 0 0 2px rgba(160, 174, 192, .16);',
      'flat-hover': '0 0 0 2px rgba(160, 174, 192, .32);',
      'input-focus': 'inset 0 0 2px rgba(59, 76, 191, 0.6)',
      sm: '0 1.5px 5px .5px rgba(0, 0, 0, .04), 0 .5px 2px .5px rgba(0, 0, 0, .04), 0 .5px 2px 1px rgba(0, 0, 0, .06)',
      'sm-hover':
        '0 1.5px 5px .5px rgba(0, 0, 0, .12), 0 .5px 2px .5px rgba(0, 0, 0, .04), 0 .5px 2px 1px rgba(0, 0, 0, .08)',
      md: '0 0 1px .5px rgba(0, 0, 0, .04), 0 .5px 1px .5px rgba(0, 0, 0, .08), 0 0 16px -2px rgba(0, 0, 0, .12), 0 12px 32px -8px rgba(0, 0, 0, .08)',
      lg: '0 0 1px .5px rgba(0, 0, 0, .08), 0 .5px 1px .5px rgba(0, 0, 0, .16), 0 8px 48px -8px rgba(0, 0, 0, .2), 0 56px 56px -16px rgba(0, 0, 0, .08)',
      none: 'none',
    },
    fontSize: {
      xxs: [
        '10px',
        {
          lineHeight: '1',
          letterSpacing: 'var(--letter-spacing-xxs)',
        },
      ],
      xs: [
        '12px',
        {
          lineHeight: '1.5',
          letterSpacing: 'var(--letter-spacing-xs)',
        },
      ],
      sm: [
        '14px',
        {
          lineHeight: '1.5',
          letterSpacing: 'var(--letter-spacing-sm)',
        },
      ],
      base: [
        '16px',
        {
          lineHeight: '1.5',
          letterSpacing: 'var(--letter-spacing-base)',
        },
      ],
      lg: [
        '18px',
        {
          lineHeight: '1.5',
          letterSpacing: 'var(--letter-spacing-lg)',
        },
      ],
      xl: [
        '20px',
        {
          lineHeight: '1.5',
          letterSpacing: 'var(--letter-spacing-xl)',
        },
      ],
      '2xl': [
        '24px',
        {
          lineHeight: '1.2',
          letterSpacing: 'var(--letter-spacing-2xl)',
        },
      ],
      '3xl': [
        '30px',
        {
          lineHeight: '1.2',
          letterSpacing: 'var(--letter-spacing-3xl)',
        },
      ],
      '4xl': [
        '36px',
        {
          lineHeight: '1.2',
          letterSpacing: 'var(--letter-spacing-4xl)',
        },
      ],
      caps: [
        '10px',
        {
          lineHeight: '1',
          letterSpacing: 'var(--letter-spacing-caps)',
          textTransform: 'uppercase',
        },
      ],
    },
    letterSpacing: {
      xs: 'var(--letter-spacing-xs)',
      sm: 'var(--letter-spacing-sm)',
      base: 'var(--letter-spacing-base)',
      lg: 'var(--letter-spacing-lg)',
      xl: 'var(--letter-spacing-xl)',
      '2xl': 'var(--letter-spacing-2xl)',
      '3xl': 'var(--letter-spacing-3xl)',
      '4xl': 'var(--letter-spacing-4xl)',
      caps: 'var(--letter-spacing-caps)',
    },
    colors: {
      white: '#ffffff',
      black: '#000000',

      gray: {
        90: '#171A22',
        80: '#2D3748',
        70: '#4A5568',
        60: '#718096',
        50: '#A0AEC0',
        40: '#CBD5E0',
        30: '#E2E8F0',
        20: '#EDF2F7',
        10: '#F7FAFC',
      },

      brand: {
        90: '#141249',
        80: '#1A1960',
        70: '#27258F',
        60: '#3432BF',
        50: '#413EEF',
        40: '#7A78F4',
        30: '#B3B2F9',
        20: '#D9D8FC',
        10: '#F1F1FE',
      },

      blue: {
        90: '#03384A',
        80: '#04546F',
        70: '#057094',
        60: '#078CB9',
        50: '#08A8DE',
        40: '#3BC8F8',
        30: '#9DE4FC',
        20: '#CEF1FD',
        10: '#E7F8FE',
      },

      pink: {
        90: '#4D0724',
        80: '#710C36',
        70: '#97114A',
        60: '#CC1965',
        50: '#EE3684',
        40: '#FC77B0',
        30: '#FEBBD8',
        20: '#FEDDEB',
        10: '#FFF1F7',
      },

      red: {
        90: '#672323',
        80: '#973030',
        70: '#C53030',
        60: '#E53E3E',
        50: '#FA5757',
        40: '#FA8383',
        30: '#FEB2B2',
        20: '#FEDCDC',
        10: '#FFF5F5',
      },

      orange: {
        90: '#623527',
        80: '#91472C',
        70: '#C05621',
        60: '#E76916',
        50: '#F38830',
        40: '#F6AD55',
        30: '#FBD38D',
        20: '#FEEBC8',
        10: '#FFF8EB',
      },

      yellow: {
        90: '#60421A',
        80: '#8D5E20',
        70: '#C38728',
        60: '#EAAA1F',
        50: '#F7C42B',
        40: '#F7D85D',
        30: '#FAEB89',
        20: '#FEF8BE',
        10: '#FEFDE6',
      },

      green: {
        90: '#284927',
        80: '#2E612D',
        70: '#348232',
        60: '#38A336',
        50: '#4DB656',
        40: '#6FCC76',
        30: '#A4E5A9',
        20: '#D0F6D3',
        10: '#F1FEF2',
      },

      teal: {
        90: '#254346',
        80: '#285E61',
        70: '#2C7A7B',
        60: '#2F9997',
        50: '#38B2AC',
        40: '#51CFC4',
        30: '#8FE5DA',
        20: '#C0F7EE',
        10: '#E6FEFA',
      },

      purple: {
        90: '#382E56',
        80: '#574195',
        70: '#6B46C1',
        60: '#805AD5',
        50: '#9F7AEA',
        40: '#B794F4',
        30: '#D6BCFA',
        20: '#EFE2FE',
        10: '#F7F0FF',
      },

      kale: {
        80: '#012B30',
        70: '#03363D',
        60: '#17494D',
        50: '#498283',
        40: '#90BBBB',
        30: '#BDD9D7',
        20: '#DAEDED',
        10: '#F5FCFC',
      },

      zblue: {
        80: '#0F3554',
        70: '#144A75',
        60: '#1F73B7',
        50: '#337FBD',
        40: '#5293C7',
        30: '#ADCCE4',
        20: '#CEE2F2',
        10: '#EDF7FF',
      },

      button: {
        'primary-outline': 'var(--color-button-primary-outline)',
        'primary-background': 'var(--color-button-primary-background)',
        'primary-border': 'var(--color-button-primary-border)',
        'primary-background-hover': 'var(--color-button-primary-background-hover)',
        'primary-border-hover': 'var(--color-button-primary-border-hover)',
        'primary-background-active': 'var(--color-button-primary-background-active)',
        'primary-border-active': 'var(--color-button-primary-border-active)',
        'primary-background-disabled': 'var(--color-button-primary-background-disabled)',
        'primary-border-disabled': 'var(--color-button-primary-border-disabled)',

        secondary: {
          background: 'var(--color-button-secondary-background)',
          'background-hover': 'var(--color-button-secondary-background-hover)',
        },
        danger: {
          background: 'var(--color-button-danger-background)',
          border: 'var(--color-button-danger-border)',
          text: 'var(--color-button-danger-text)',
          'border-hover': 'var(--color-button-danger-border-hover)',
          'text-hover': 'var(--color-button-danger-text-hover)',
          'background-active': 'var(--color-button-danger-background-active)',
          'text-disabled': 'var(--color-button-danger-text-disabled)',
          'background-disabled': 'var(--color-button-danger-background-disabled)',
          'border-disabled': 'var(--color-button-danger-border-disabled)',
        },
      },
      app: {
        background: 'var(--color-app-background)',
      },
      nav: {
        background: 'var(--color-nav-background)',
        'secondary-label': 'var(--color-nav-secondary-label)',
        'tertiary-label': 'var(--color-nav-tertiary-label)',
        'tertiary-label-hover': 'var(--color-nav-tertiary-label-hover)',
        border: 'var(--color-nav-border)',
        'border-hover': 'var(--color-nav-border-hover)',
        'item-background-hover': 'var(--color-nav-item-background-hover)',
        'item-background-active': 'var(--color-nav-item-background-active)',
        'item-background-active-hover': 'var(--color-nav-item-background-active-hover)',
        'item-content-hover': 'var(--color-nav-item-content-hover)',
        'item-content-active': 'var(--color-nav-item-content-active)',
        icon: 'var(--color-nav-icon)',
        'icon-hover': 'var(--color-nav-icon-hover)',
      },
      applicationBanner: {
        'background-info': 'var(--color-application-banner-background-info)',
        'background-warning': 'var(--color-application-banner-background-warning)',
        'background-error': 'var(--color-application-banner-background-error)',
        'background-success': 'var(--color-application-banner-background-success)',
        demoPillColor: 'var(--color-application-banner-demo-pill)',
      },
      subnav: {
        background: 'var(--color-subnav-background)',
      },
      sidebar: {
        link: 'var(--color-sidebar-link)',
        'link-background-hover': 'var(--color-sidebar-link-background-hover)',
        'link-background-active': 'var(--color-sidebar-link-background-active)',
        'link-fill': 'var(--color-sidebar-link-fill)',
        'link-fill-active': 'var(--color-sidebar-link-fill-active)',
        'link-fill-hover': 'var(--color-sidebar-link-fill-hover)',
      },
      link: 'var(--color-link)',
      form: {
        'selected-background': 'var(--color-form-selected-background)',
        'disabled-background': 'var(--color-form-disabled-background)',
        'focus-border': 'var(--color-form-focus-border)',
        breadcrumbs: 'var(--color-form-breadcrumbs)',
        'breadcrumbs-active': 'var(--color-form-breadcrumbs-active)',
      },

      active: {
        primary: 'var(--color-active-primary)',
        'primary-hover': 'var(--color-active-primary-hover)',
        secondary: 'var(--color-active-secondary)',
        'secondary-hover': 'var(--color-active-secondary-hover)',
        border: 'var(--color-active-border)',
        icon: 'var(--color-active-icon)',
      },

      goal: {
        background: 'var(--color-goal-background)',
        'background-dark': 'var(--color-goal-background-dark)',
      },
      badge: {
        dark: {
          bg: 'var(--color-badge-dark-background)',
          color: 'var(--color-badge-dark-text)',
        },
        yellow: {
          bg: 'var(--color-badge-yellow-background)',
          color: 'var(--color-badge-yellow-text)',
        },
        pink: {
          bg: 'var(--color-badge-pink-background)',
          color: 'var(--color-badge-pink)',
        },
      },
      pin: {
        background: 'var(--color-pin-background)',
        border: 'var(--color-pin-border)',
      },
      banner: {
        background: 'var(--color-banner-background)',
        border: 'var(--color-banner-border)',
      },
      tag: {
        brand: { bg: 'var(--color-tag-background)' },
        light: { bg: 'var(--color-tag-light-background)', color: 'var(--color-tag-light-text)' },
      },
      audio: {
        'track-hover': 'var(--color-audio-track-hover)',
      },
      graph: {
        60: 'var(--color-graph-brand-60)',
        30: 'var(--color-graph-brand-30)',
        20: 'var(--color-graph-brand-20)',
        10: 'var(--color-graph-brand-10)',
      },
      pill: {
        'brand-color': 'var(--color-pill-brand-text)',
        'brand-bg': 'var(--color-pill-brand-background)',
      },
      calendar: {
        90: 'var(--color-calendar-900)',
        80: 'var(--color-calendar-800)',
        70: 'var(--color-calendar-700)',
        60: 'var(--color-calendar-600)',
        50: 'var(--color-calendar-500)',
        40: 'var(--color-calendar-400)',
        30: 'var(--color-calendar-300)',
        20: 'var(--color-calendar-200)',
        10: 'var(--color-calendar-100)',
      },
      alert: {
        text: 'var(--color-alert-text)',
      },
    },
    screens: {
      md: '1201px',
      lg: '1320px',
      xl: '1601px',
      720: { max: '720px' },
      1400: { max: '1400px' },
      1401: { min: '1401px' },
    },
    borderRadius: {
      none: '0',
      sm: '0.25rem',
      DEFAULT: '0.375rem',
      md: '0.5rem',
      lg: '0.75rem',
      xl: '1rem',
      full: '9999px',
    },
    strokeWidth: {
      s: '1.32px',
      m: '1.6px',
      l: '2px',
      xl: '2.4px',
    },
    extend: {
      minWidth: ({ theme }) => ({
        ...theme('spacing'),
      }),
      minHeight: ({ theme }) => ({
        ...theme('spacing'),
      }),
      width: {
        150: '37.5rem',
      },
      height: {
        '10v': '10vh',
        '20v': '20vh',
        '30v': '30vh',
        '40v': '40vh',
        '50v': '50vh',
        '60v': '60vh',
        '70v': '70vh',
        '80v': '80vh',
        '90v': '90vh',
      },
      maxHeight: {
        '10v': '10vh',
        '20v': '20vh',
        '30v': '30vh',
        '40v': '40vh',
        '50v': '50vh',
        '60v': '60vh',
        '70v': '70vh',
        '80v': '80vh',
        '90v': '90vh',
      },
      opacity: {
        24: '0.24',
      },
      backgroundColor: {
        transparent: 'transparent',
      },
      backdropBlur: {
        xs: '2px',
      },
      transitionProperty: {
        height: 'height',
        spacing: 'margin, padding',
        visibility: 'visibility',
        'filter-opacity': 'filter, opacity',
      },
      scale: {
        120: '1.2',
      },
    },
  },
  plugins: [
    require('@tailwindcss/typography'),
    require('tailwindcss/plugin')(function ({ addUtilities, theme }) {
      addUtilities({
        '.content-link': {
          display: 'inline-block',
          fontWeight: theme('fontWeight.medium'),
          color: theme('colors.link'),
          '&:hover': {
            textDecorationLine: 'underline',
          },
        },
        '.icon-s': {
          display: 'inline',
          height: theme('spacing.3'),
          width: theme('spacing.3'),
          maxWidth: 'initial',
          strokeWidth: theme('strokeWidth.s'),
          '*': {
            'vector-effect': 'non-scaling-stroke',
          },
        },
        '.icon-m': {
          display: 'inline',
          height: theme('spacing.4'),
          width: theme('spacing.4'),
          maxWidth: 'initial',
          strokeWidth: theme('strokeWidth.m'),
          '*': {
            'vector-effect': 'non-scaling-stroke',
          },
        },
        '.icon-l': {
          display: 'inline',
          height: theme('spacing.6'),
          width: theme('spacing.6'),
          maxWidth: 'initial',
          strokeWidth: theme('strokeWidth.l'),
          '*': {
            'vector-effect': 'non-scaling-stroke',
          },
        },
        '.icon-xl': {
          display: 'inline',
          height: theme('spacing.8'),
          width: theme('spacing.8'),
          maxWidth: 'initial',
          strokeWidth: theme('strokeWidth.xl'),
          '*': {
            'vector-effect': 'non-scaling-stroke',
          },
        },
        '.ow-anywhere': {
          overflowWrap: 'anywhere',
        },
        '.text-caps': {
          textTransform: 'uppercase',
        },
      })


    }),
    function ({ addBase, theme }) {
      const colorsWithoutShade = ['white', 'black']

      function extractColorVars(allColors, colorGroup = '') {
        return Object.keys(allColors).reduce((vars, colorKey) => {
          const value = allColors[colorKey]
          const cssVariable = colorsWithoutShade.includes(value) ? `--${colorGroup}` : `--${colorGroup}-${colorKey}`
          const newVars = typeof value === 'string' ? { [cssVariable]: value } : extractColorVars(value, colorKey)

          return { ...vars, ...newVars }
        }, {})
      }

      addBase({
        ':root': extractColorVars(theme('colors')),
      })
    },
  ],
}
