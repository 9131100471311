import { ref, computed, onMounted } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { getLookerDashboards } from '@/modules/dashboard/api'
import { getLookerDashboardByName } from '@/modules/dashboard/looker/utils/lookup'
import type { LookerDashboard } from '@/modules/dashboard/looker/types'

import { session } from './useSession'

export const useDashboardsList = createSharedComposable(() => {
  const dashboardList = ref<LookerDashboard[]>([])

  const lookerAutoQaDashboard = computed(() => getLookerDashboardByName(dashboardList.value, 'auto_qa'))
  const lookerReviewsDashboard = computed(() => {
    const dashboardName = session.features.lookerReviewNoPassRateDashboard ? 'reviews_limited' : 'reviews'
    return getLookerDashboardByName(dashboardList.value, dashboardName)
  })
  const lookerReviewsAgentDashboard = computed(() => {
    const dashboardName = session.features.lookerReviewNoPassRateDashboard ? 'reviews_limited_agent' : 'reviews_agent'
    return getLookerDashboardByName(dashboardList.value, dashboardName)
  })
  const lookerBotQaDashboard = computed(() => getLookerDashboardByName(dashboardList.value, 'botqa'))
  const lookerAssignmentsDashboard = computed(() => getLookerDashboardByName(dashboardList.value, 'assignments'))

  onMounted(async () => {
    if (
      !session.features.lookerReviewsDashboard &&
      !session.features.lookerAutoQaDashboard &&
      !session.features.lookerBotQaDashboard &&
      !session.features.lookerAssignmentsDashboard
    )
      return

    const { dashboards } = await getLookerDashboards()
    dashboardList.value = dashboards
  })

  return {
    dashboardList,
    lookerAutoQaDashboard,
    lookerReviewsDashboard,
    lookerReviewsAgentDashboard,
    lookerBotQaDashboard,
    lookerAssignmentsDashboard,
  }
})
