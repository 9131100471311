import type { AutoQaRatingCategory } from '@klausapp/services/autoqa'
import { type Scale, type ScaleType, SCALE_VALUES } from '@/data/scales'
import type { Scorecard } from '@/modules/shared/TicketContent/types/scorecard'
import { type RootCause, RequireReasonVisibility, type AutoQaCategoryType } from '@/types/category'

/**
 * This utils module should house functional methods to better unify and
 * re-use the different ratings and grouping methods used in:
 *
 * Feedback.vue
 * ReviewSidebar.vue (TicketContent)
 * Review.vue (extension)
 */

// Golang services types
export interface Category {
  categoryDescription?: string
  categoryId: number
  categoryName: string
  critical: boolean
  groupId?: number
  groupName?: string
  groupPosition?: number
  isArchived: boolean
  multipleRequireReasons: boolean
  position: number
  requireReason: boolean
  requireReasonVisibility: RequireReasonVisibility
  freeTextAllowed: boolean
  rootCauses: RootCause[]
  scale: ScaleType
  scorecards: Scorecard[]
  weight: number
  autoQaCategory?: AutoQaCategoryType
  autoQaCustomCategoryTemplateId?: string
  autoQaRatingCategory?: AutoQaRatingCategory
}

export interface Rating extends Category {
  rating?: number
  cause?: string[]
  failCategory?: boolean
}

export interface CategoryGroup {
  id: number
  name: string
  position: number
  categories: Rating[]
}

export type GivenRatings = Record<string, Rating>

// This value is used to mark "N/A" ratings if the Account-level Scale settings allow it
export const RATING_NA = 42

export const populateWithNA = (givenRatings: GivenRatings): Rating[] =>
  Object.values(givenRatings).map((r) => {
    return { ...r, rating: r.rating ?? RATING_NA }
  })

export const groupCategories = <T extends { id: number; name: string; position: number }>(
  categories: Rating[],
  groups: T[],
  keepEmptyGroups = false,
): CategoryGroup[] => [
  {
    id: 0,
    name: 'Ungrouped',
    categories: categories.filter((cat) => !cat.groupId).sort((a, b) => (a.position || 0) - (b.position || 0)),
    position: 0,
  },
  ...groups
    .map((group) => ({
      ...group,
      categories: categories
        .filter((cat) => cat.groupId === group.id)
        .sort((a, b) => (a.position || 0) - (b.position || 0)),
    }))
    .filter((group) => keepEmptyGroups || group.categories.length),
]

export const isRated = (r?: { rating?: number }) => r && ![null, undefined, RATING_NA].includes(r.rating)

export const isValidComment = (comment: string) => {
  const content = comment.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '').replace(/&#39;/g, `'`)
  return !!content.trim().length
}

export const hasRootCauses = (cause: string[] | undefined) => !!cause?.length

export const isNegativeRating = (rating: number, scale: Scale[]) => rating < Math.floor((scale?.length ?? 0) / 2)

export const isNeutralRating = (rating: number, scale: Scale[]) => {
  if (scale.length % 2 !== 1) {
    // Array does not have a neutral rating (i.e. it's either a binary or 4-point scale)
    return false
  }

  const neutralRatingIndex = Math.floor(scale.length / 2)
  const neutralRating = scale[neutralRatingIndex].label - 1

  return neutralRating === rating
}

export const isRootCauseRequiredForRating = (rating: number, ratingCategory: Rating): boolean => {
  const { requireReason, requireReasonVisibility, scale } = ratingCategory

  if (!requireReason) return false
  if (requireReasonVisibility === RequireReasonVisibility.All) return true

  if (requireReasonVisibility === RequireReasonVisibility.NegativeAndNeutral) {
    return isNegativeRating(rating, SCALE_VALUES[scale]) || isNeutralRating(rating, SCALE_VALUES[scale])
  }

  return isNegativeRating(rating, SCALE_VALUES[scale])
}
