import { SettingsIcon, FlagIcon } from '../icons'
import ZHelpIcon from './assets/icons/z-help.svg'
import ZDashboardIcon from './assets/icons/z-dashboard.svg'
import ZChatIcon from './assets/icons/z-chat.svg'
import ZCheckboxIcon from './assets/icons/z-checkbox.svg'
import ZInboxIcon from './assets/icons/z-inbox.svg'
import ZCoachingIcon from './assets/icons/z-coaching.svg'
import ZProductTrayIcon from './assets/icons/z-product-tray.svg'

export default {
  dashboard: ZDashboardIcon,
  conversations: ZChatIcon,
  assignments: ZCheckboxIcon,
  activity: ZInboxIcon,
  coaching: ZCoachingIcon,
  settings: SettingsIcon,
  tasks: ZCheckboxIcon,
  users: FlagIcon,
  help: ZHelpIcon,
  productTray: ZProductTrayIcon,
}
