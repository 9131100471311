import { reactive, computed, watch } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import i18n from '@/i18n'
import analytics from '@/utils/analytics'
import { bus } from '@/utils/bus'
import { session } from '@/composables/useSession'
import { getWorkspaceUsers } from '@/modules/user-management/api'
import { type WorkspaceUser, UserStatus } from '@/modules/user-management/types'
import { isWorkspaceRoleIncluded } from '@/utils/roleUtils'
import { layoutState } from '@/composables/usePageLayout'
import useTicketRouteParams from '@/composables/useTicketRouteParams'

import type { Feedback } from '../types/feedback'
import type { Dispute } from '../types/dispute'
import { getDisputes, type DisputePayload, disputeReview, disputeReviewee, removeDispute } from '../api/disputes'

export interface DisputeDetails extends Omit<Dispute, 'id'> {
  id?: string
}

export type DisputeAction = 'APPROVE' | 'REJECT' | 'EDIT'

export interface State {
  dispute?: DisputeDetails
  disputing: boolean
  disputesList: Dispute[]
  users: WorkspaceUser[]
  assignees: WorkspaceUser[]
  reviewId?: string
  action?: DisputeAction
}

export const defaultState: State = {
  dispute: undefined,
  disputing: false,
  disputesList: [],
  users: [],
  assignees: [],
  reviewId: undefined,
  action: undefined,
}

export default createSharedComposable(() => {
  const { connectionId, conversationId, disputeId } = useTicketRouteParams()
  const state = reactive(defaultState)

  loadDisputeAssignees()

  const translationKey = computed(() => {
    const dispute = state.dispute
    const status = state.action || dispute?.status
    return `${dispute?.type}-${status}`
  })

  const disputeTitle = computed(() => {
    const key = translationKey.value
    const translationMap = {
      'RATINGS-NEW': 'conversations.sidebar.disputes.titles.dispute_review',
      'RATINGS-APPROVE': 'conversations.sidebar.disputes.titles.approve_review',
      'RATINGS-REJECT': 'conversations.sidebar.disputes.titles.reject_review',
      'RATINGS-EDIT': 'conversations.sidebar.disputes.titles.edit_review',
      'REVIEWEE-NEW': 'conversations.sidebar.disputes.titles.dispute_reviewee',
      'REVIEWEE-EDIT': 'conversations.sidebar.disputes.titles.dispute_reviewee',
      'REVIEWEE-APPROVE': 'conversations.sidebar.disputes.titles.approve_reviewee',
      'REVIEWEE-REJECT': 'conversations.sidebar.disputes.titles.reject_reviewee',
    }

    return translationMap[key] ? i18n.t(translationMap[key], [state.dispute?.assignee.name]) : ''
  })

  function getDisputeStatus(dispute: Dispute) {
    const i18nMap = {
      NEW: 'conversations.sidebar.disputes.status.new',
      ACCEPTED: 'conversations.sidebar.disputes.status.approved',
      PARTIALLY_ACCEPTED: 'conversations.sidebar.disputes.status.partially_accepted',
      REJECTED: 'conversations.sidebar.disputes.status.rejected',
    }
    return i18n.t(i18nMap[dispute.status])
  }

  const _getBlankDispute = (review: Feedback): DisputeDetails => {
    const reviewer = state.users.find((u) => u.id === review.reviewerId) || {
      id: review.reviewerId,
      name: review.reviewerName,
    }
    const assignee = {
      id: (reviewer.id || '').toString(),
      name: reviewer.name,
      avatar: '',
    }
    const categories = review.ratings.map((r) => ({
      id: r.categoryId,
      expectedRating: r.rating,
      note: '',
      rootCause: r.cause,
    }))

    return {
      comment: {
        Id: '0',
        comment: '',
        commentTags: [],
        seen: false,
      },
      categories,
      status: 'NEW',
      type: 'RATINGS',
      reviewId: review.id,
      originalReview: review,
      steps: [],
      assignee,
      createdAt: '',
      createdBy: assignee,
      viewed: null,
    }
  }

  async function loadDisputes(connId?: number, convId?: string) {
    if (!connId) connId = connectionId.value
    if (!convId) convId = conversationId.value

    const { disputes } = await getDisputes({ connectionId: connId, conversationId: convId })
    state.disputesList = disputes
    loadDisputeAssignees()
  }

  async function loadDisputeAssignees() {
    if (!session?.workspace?.id) return

    const { users } = await getWorkspaceUsers(session.workspace.id)
    const activeUserId = session.user.id.toString()
    state.users = users.slice().sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))

    const assignees = users.filter(
      (u) =>
        u.id !== activeUserId &&
        u.status === UserStatus.Active &&
        !(u.workspaceRole && !isWorkspaceRoleIncluded(u.workspaceRole, ['MANAGER', 'LEAD'])),
    )

    // If original reviewer is not in the list of assignees, add them
    const review = state.dispute?.originalReview
    const reviewer = review?.reviewerId
    if (reviewer && reviewer !== activeUserId && !assignees.find((u) => u.id === reviewer)) {
      const user = users.find((u) => u.id === reviewer)
      user && assignees.push(user)
    }

    state.assignees = assignees.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
  }

  function startDisputingReview(review: Feedback) {
    loadDisputeAssignees()
    state.dispute = _getBlankDispute(review)
    state.disputing = true
    state.dispute.type = 'RATINGS'
    state.reviewId = review.id
    analytics.disputeStartReview()

    if (!layoutState.value.rightSidebar) bus.$emit('toggle-review-sidebar')
  }

  function startDisputingReviewee(review: Feedback) {
    loadDisputeAssignees()
    state.dispute = _getBlankDispute(review)
    state.disputing = true
    state.dispute.type = 'REVIEWEE'
    state.reviewId = review.id
    analytics.disputeStartReviewee()

    if (!layoutState.value.rightSidebar) bus.$emit('toggle-review-sidebar')
  }

  function resetDispute() {
    if (!state.action) {
      state.dispute = undefined
    }

    discardDispute()
  }

  function discardDispute() {
    state.disputing = false
    state.action = undefined
  }

  async function createNewDispute(payload: DisputePayload) {
    const method = !payload.data.categories ? disputeReviewee : disputeReview
    const disputes = await method(payload)

    resetDispute()
    state.disputesList = disputes.disputes
    return disputes
  }

  async function deleteDispute(disputeId?: string) {
    const dispute = disputeId || state.dispute?.id
    if (!dispute || !connectionId.value || !conversationId.value) return

    const { disputes } = await removeDispute({
      disputeId: dispute,
      connectionId: connectionId.value,
      conversationId: conversationId.value,
    })
    state.disputesList = disputes
    resetDispute()
    analytics.disputeDelete()
  }

  function getReviewDisputes(reviewId: string) {
    return state.disputesList.filter((dispute) => dispute.reviewId === reviewId)
  }

  function selectDisputeById(disputeId: string | undefined) {
    if (!disputeId) return
    state.dispute = state.disputesList.find(({ id }) => id === disputeId)
  }

  watch(
    () => disputeId.value,
    async () => {
      if (!session.features.disputes || !disputeId.value) return

      resetDispute()
      if (state.disputesList.length) return selectDisputeById(disputeId.value)

      if (connectionId.value && conversationId.value) {
        await loadDisputes(connectionId.value, conversationId.value)
        selectDisputeById(disputeId.value)
      }
    },
    { immediate: true },
  )

  watch(
    () => state.disputesList,
    (disputes) => {
      if (!disputes.length) return
      selectDisputeById(disputeId.value)
    },
    { immediate: true },
  )

  return {
    state,
    translationKey,
    disputeTitle,
    getDisputeStatus,
    loadDisputes,
    loadDisputeAssignees,
    startDisputingReview,
    startDisputingReviewee,
    resetDispute,
    discardDispute,
    createNewDispute,
    deleteDispute,
    getReviewDisputes,
    selectDisputeById,
  }
})
