import { type Slot } from 'vue'

export const hasSlotContent = (slot: Slot | undefined | null) => {
  if (!slot) return false

  return slot().some((vnode) => {
    if (Array.isArray(vnode.children)) {
      return !!vnode.children.length
    }

    return vnode.type !== Comment
  })
}
