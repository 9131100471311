import type { NormalizedOptions } from 'ky'
import type {
  TicketUrlResponse,
  AutoInsightFeedbackRequest,
  TicketMessagesSentimentResponse as ServicesTicketMessageSentimentResponse,
  TicketMessageSentiment as ServicesTicketMessageSentiment,
  ConversationEventResponse,
} from '@klausapp/services/tickets'
import type { AutoQaReviewForConversationRequest, AutoQaReviewForConversationResponse } from '@klausapp/services/autoqa'
import type { GroupedReaction } from '@klausapp/services/reviews'
import type { KlausSentiment } from '@/types/sentiment'
import { oldApi, api, errorHandlerHook, crApi } from '@/api'
import { session } from '@/composables/useSession'
import type { TicketData } from '@/modules/conversations/types'
import type { Comment, CommentTag, Feedback, ReactionType } from '../types/feedback'
import type { Scorecard } from '../types/scorecard'

interface ReviewBody {
  comment: string | null
  commentTags: CommentTag[]
  conversationId: string
  messageId?: string
  transcriptionId?: string
  transcriptionMessageId?: string
  ratings: {
    categoryId: number
    rating: number
    cause?: string[]
  }[]
  revieweeId?: number
  botRevieweeId?: string
}

export interface ModifyReview {
  connectionId: number
  reviewId: string
  data?: ReviewBody
}

type FeedbackResponse = Promise<{ feedback: Feedback[] }>

const getRequestPrefix = () => `payments/${session.account.id}/teams/${session.workspace.id}`

export const getRequestHeaders = () => ({ 'X-Klaus-Workspace': session.workspace.id.toString() })

/**
 * This sets the "viewed" state for the whole conversation - it's an indication for
 * the user if they've opened this conversation on Klaus before
 */
export const markTicketViewed = (connectionId: number, externalId: string) =>
  api.put(`ticket/connection/${connectionId}/mark-viewed`, {
    headers: getRequestHeaders(),
    searchParams: { externalId },
  })

export const toggleHighlight = ({
  conversationExternalId,
  paymentTokenId,
  messageExternalId,
}: {
  conversationExternalId: string
  paymentTokenId: number
  messageExternalId?: string
}) =>
  api.put(`ticket/connection/${paymentTokenId}/star`, {
    headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
    json: { messageExternalId, conversationExternalId },
  })

export const postReview = (payload: { connectionId: number; data: ReviewBody }): Promise<Feedback> =>
  api
    .post(`ticket/connection/${payload.connectionId}/reviews`, { json: payload.data, headers: getRequestHeaders() })
    .json()

export const postAssignmentV2Review = (payload: {
  connectionId: number
  assignmentId: string
  data: ReviewBody
}): Promise<Feedback> =>
  api
    .post(`ticket/connection/${payload.connectionId}/assignment/v2/${payload.assignmentId}/reviews`, {
      json: payload.data,
      headers: getRequestHeaders(),
    })
    .json()

export const updateReview = ({ connectionId, reviewId, data }: ModifyReview): FeedbackResponse =>
  api
    .put(`ticket/connection/${connectionId}/reviews/${reviewId}`, {
      json: data,
      headers: getRequestHeaders(),
    })
    .json()

export const deleteReview = ({ connectionId, reviewId }: ModifyReview): FeedbackResponse =>
  api.delete(`ticket/connection/${connectionId}/reviews/${reviewId}`, { headers: getRequestHeaders() }).json()

export const setReviewAsViewed = ({ connectionId, reviewId }: ModifyReview) =>
  api.put(`ticket/connection/${connectionId}/reviews/${reviewId}/viewed`, { headers: getRequestHeaders() }).json()

export const getFeedback = (params: {
  connectionId: number | string
  conversationId: string
  language?: string
}): FeedbackResponse => {
  const query = new URLSearchParams()
  query.append('conversationId', params.conversationId)
  if (params.language) query.append('language', params.language)
  return api.get(`ticket/connection/${params.connectionId}/feedback?${query}`, { headers: getRequestHeaders() }).json()
}

export const postComment = (payload: { connectionId: number; data: Comment }): FeedbackResponse =>
  api
    .post(`ticket/connection/${payload.connectionId}/comments`, { json: payload.data, headers: getRequestHeaders() })
    .json()

export const createCommentReply = (payload: { connectionId: number; id: string; data: Comment }): FeedbackResponse =>
  api
    .post(`ticket/connection/${payload.connectionId}/comments/${payload.id}/reply`, {
      json: payload.data,
      headers: getRequestHeaders(),
    })
    .json()

export const createReviewReply = (payload: { connectionId: number; id: string; data: Comment }): FeedbackResponse =>
  api
    .post(`ticket/connection/${payload.connectionId}/reviews/${payload.id}/reply`, {
      json: payload.data,
      headers: getRequestHeaders(),
    })
    .json()

export const editComment = (payload: { connectionId: number | string; data: Comment }): FeedbackResponse =>
  api
    .put(`ticket/connection/${payload.connectionId}/comments/${payload.data.id}`, {
      json: payload.data,
      headers: getRequestHeaders(),
    })
    .json()

export const deleteComment = (payload: { connectionId: number; id: string }): FeedbackResponse =>
  api
    .delete(`ticket/connection/${payload.connectionId}/comments/${payload.id}`, { headers: getRequestHeaders() })
    .json()

export const setCommentAsViewed = (payload: { connectionId: number; id: string }) =>
  api
    .put(`ticket/connection/${payload.connectionId}/comments/${payload.id}/viewed`, { headers: getRequestHeaders() })
    .json()

let getTicketController = new AbortController()

export const abortOldTicketRequests = () => {
  getTicketController.abort()
  getTicketController = new AbortController()
}

export const getTicketSpanner = (externalId: string, connectionId: number): Promise<{ data: TicketData }> => {
  return api
    .extend({ hooks: { afterResponse: undefined } })
    .extend({ hooks: { afterResponse: [] } })
    .get(`ticket/connection/${connectionId}/data`, {
      headers: getRequestHeaders(),
      searchParams: { externalId },
      signal: getTicketController.signal,
    })
    .json<TicketData>()
    .then((data) => ({
      data: {
        ...data,
        paymentTokenId: Number(data.paymentTokenId),
        // TODO: Get rid of this hack and get rid of string type (in TicketMessage) when deleting this
        // Without the hack reviewing messages won't work
        externalComments: data.externalComments?.map((c) => ({
          ...c,
          id: c.externalId,
        })),
      },
    }))
}

export const getTicketBody = (externalId: string, connectionId: number) =>
  oldApi
    .extend({ hooks: { afterResponse: undefined } })
    .extend({ hooks: { afterResponse: [] } })
    .get(`${getRequestPrefix()}/tickets/connection/${connectionId}/${externalId}/body`, {
      signal: getTicketController.signal,
    })
    .json()

export const getTicketRecordings = (id: string, connectionId: string, signal?: AbortSignal) => {
  return oldApi
    .get(`${getRequestPrefix()}/tickets/v2/connection/${connectionId}/${encodeURIComponent(id)}/recording-urls`, {
      signal,
    })
    .json<{ data: Record<string, string> }>()
}

interface GetCommentRecordingParams {
  connectionId: number
  commentId: string
  ticketId: string
}
export function getCommentRecording(
  { commentId, connectionId, ticketId }: GetCommentRecordingParams,
  signal: AbortSignal,
) {
  return crApi
    .get(`integration-proxy/api/ticket/${connectionId}/external/${ticketId}/message/${commentId}/recording`, {
      headers: getRequestHeaders(),
      signal,
    })
    .blob()
}

export const getConversationEvents = (externalId: string, connectionId: number) =>
  api
    .get(`ticket/connection/${connectionId}/conversationEvents`, {
      headers: getRequestHeaders(),
      searchParams: { externalId },
      signal: getTicketController.signal,
    })
    .json<ConversationEventResponse>()

export type TicketMessageSentiment = ServicesTicketMessageSentiment & {
  sentiment: KlausSentiment
}
export type TicketMessageSentimentResponse = ServicesTicketMessageSentimentResponse & {
  messageSentiment: TicketMessageSentiment[]
}

export const getTicketSentiment = (externalId: string, connectionId: number) =>
  api
    .get(`ticket/connection/${connectionId}/messagesSentiment`, {
      headers: getRequestHeaders(),
      searchParams: { externalId },
      signal: getTicketController.signal,
    })
    .json<TicketMessageSentimentResponse>()

export const getTicketUrl = (
  { connectionId, externalId }: { connectionId: number; externalId?: string },
  signal: AbortSignal,
): Promise<TicketUrlResponse> =>
  api
    .get(`ticket/connection/${connectionId}/url`, {
      headers: getRequestHeaders(),
      searchParams: externalId ? { externalId } : {},
      signal,
    })
    .json<TicketUrlResponse>()

export const getScorecards = (externalId: string, connectionId: number | string) =>
  api
    .extend({ hooks: { afterResponse: undefined } })
    .get(`ticket/connection/${connectionId}/rating-categories`, {
      headers: getRequestHeaders(),
      searchParams: { externalId },
      hooks: {
        afterResponse: [
          (_request: Request, _options: NormalizedOptions, response: Response) => {
            if (response.status === 404) return new Response('{"scorecards": []}')
            return response
          },
          errorHandlerHook,
        ],
      },
    })
    .json<{ scorecards: Scorecard[] }>()

export const getInternalCommentTags = (): Promise<{ data: string[]; total: number }> =>
  oldApi.get(`${getRequestPrefix()}/internal-tags`).json()

export const getTranslation = (
  externalId: string,
  connectionId: number,
  language: string,
): Promise<{ body: Record<string, string | { body: string }> }> =>
  api
    .get(`ticket/connection/${connectionId}/messages/translation`, {
      headers: getRequestHeaders(),
      searchParams: { externalId, language },
    })
    .json()

export const getAutoQaConversationReviews = (
  params: AutoQaReviewForConversationRequest,
): Promise<AutoQaReviewForConversationResponse> => {
  const { connectionId, conversationId } = params
  const query = new URLSearchParams({ connectionId, conversationId })
  return api.get(`autoqa/conversation-reviews?${query}`, { headers: getRequestHeaders() }).json()
}

export const toggleEmojiReactions = (
  connectionId: number,
  feedbackId: string,
  type: ReactionType,
  emojis: string[],
): Promise<{ reactions: GroupedReaction[] }> => {
  return api
    .post(`ticket/connection/${connectionId}/${type}/${feedbackId}/toggle-reaction`, {
      json: { emojis },
      headers: getRequestHeaders(),
    })
    .json()
}

export const postInsightFeedback = (
  connectionId: number,
  externalTicketId: string,
  type: string,
  comment: string | undefined,
  rejected: boolean,
  customSpotlightId?: string,
): Promise<AutoInsightFeedbackRequest> =>
  api
    .post(`ticket/connection/${connectionId}/autoInsightFeedback`, {
      json: { externalTicketId, type, comment, rejected, customSpotlightId },
      headers: getRequestHeaders(),
    })
    .json()

export const updateInsightFeedback = (
  connectionId: number,
  externalTicketId: string,
  type: string,
  comment: string | undefined,
  rejected: boolean,
  customSpotlightId?: string,
): Promise<AutoInsightFeedbackRequest> =>
  api
    .put(`ticket/connection/${connectionId}/autoInsightFeedback`, {
      json: { externalTicketId, type, comment, rejected, customSpotlightId },
      headers: getRequestHeaders(),
    })
    .json()

export const deleteTicket = async (connectionId: number, externalId: string) =>
  await api.delete(`ticket/connection/${connectionId}/ticket/${externalId}`, {
    headers: getRequestHeaders(),
  })
