import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { ref, computed } from 'vue'
import type { AssignmentV2_UserAssignment } from '@klausapp/services/assignments_v2'
import useTasks from '@/composables/useTasks'
import useTaskGoals from '@/composables/useTaskGoals'
import { track } from '@/utils/analytics'
import { formatReplyDuration } from '@/utils/duration'
import useTicketRouteParams from '@/composables/useTicketRouteParams'

export default function () {
  const { activeAssignmentCycles } = useTasks()
  const { goalState } = useTaskGoals()
  const { assignmentId } = useTicketRouteParams()

  const expanded = ref(false)
  const showCompletedCycles = ref(false)

  dayjs.extend(isToday)

  const openOlderSessions = (trackType: string) => {
    showCompletedCycles.value = !showCompletedCycles.value

    if (trackType) {
      track(showCompletedCycles.value ? `Older ${trackType} expanded` : `Older ${trackType} collapsed`)
    }
  }

  const personalAssignments = computed(() => {
    return activeAssignmentCycles.value.filter((cycle) => cycle?.isReviewer)
  })

  const toggle = () => {
    expanded.value = !expanded.value
    track(`Tasks ${expanded.value ? 'expanded' : 'collapsed'}`)
  }
  const isCriticalTime = (cycleEnd: dayjs.Dayjs) => {
    const daysLeft = cycleEnd.diff(new Date(), 'day')

    return daysLeft <= 2
  }
  const getCount = (item: AssignmentV2_UserAssignment) => {
    if (item.id === assignmentId.value) return goalState?.reviewCount
    return item.reviewCount
  }
  const getPeriod = (item: AssignmentV2_UserAssignment) =>
    formatReplyDuration(dayjs(new Date()), dayjs(item.cycleEnd).local())

  const isCurrentCycle = (cycle: AssignmentV2_UserAssignment) => !!assignmentId.value && cycle.id === assignmentId.value

  return {
    openOlderSessions,
    personalAssignments,
    showCompletedCycles,
    expanded,
    toggle,
    isCriticalTime,
    getCount,
    getPeriod,
    isCurrentCycle,
  }
}
