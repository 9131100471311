import { AnalyticsBrowser } from '@segment/analytics-next'
import type { PinReference_Type, PinReference } from '@klausapp/services/pins'

import config, { embeddedRuntime } from '@/config'
import type { Session } from '@/types/session'
import type { StatisticalDistributionPeriod } from '@/modules/conversations/types'
import type { PinModalState } from '@/composables/usePinModal'
import type { FilterTimeStep } from '@/modules/dashboard/types'
import { getHubspotUserToken } from '@/modules/auth/api'

let analytics: AnalyticsBrowser | undefined

const isBot = navigator.userAgent.startsWith('klaus/monitoring/') || 'Cypress' in window

if (!isBot && config.analyticsId) {
  analytics = AnalyticsBrowser.load(
    { writeKey: config.analyticsId, cdnURL: 'https://eyes-cdn.klausapp.com' },
    { obfuscate: true },
  )
}

export const track = (name: string, args?: Record<string, any>) => {
  if (!analytics) return console.info(name, args)
  analytics.track(name, args)
}

export const identify = (session: Session) => {
  if (!analytics) return

  analytics.identify(session.user.id.toString(), {
    name: session.user.name,
    email: session.user.email,
    locale: session.user.locale,
    joinedAt: session.user.joinedAt,
    extensionUser: !!embeddedRuntime(),
    screenWidth: window.screen.width,
    windowWidth: window.innerWidth,
    screenHeight: window.screen.height,
    windowHeight: window.innerHeight,
    // For Amplitude, to distinguish between public website and app users
    app: true,
  })

  analytics.group(session.account.id as any, {
    name: session.account.name,
    created_at: session.account.createdAt,
    // TODO: Remove when we figure out Segment mapping issues
    subscriptionStatus: session.account.trialEndAt ? 'trial' : session.account.status,
  })

  // Birdie
  window.birdieSettings = {
    contact_name: session.user.name,
    contact_email: session.user.email,
    contact_id: session.user.id,
  }
}

export default {
  appUpdateReload: () => track('App update reload'),
  modalOpened: (title: string) => track('Modal opened', { title }),
  modalClosed: (title: string) => track('Modal closed via Esc', { title }),
  filterBarMoreFiltersButtonToggled: (dashboardName: string) => {
    track(`${dashboardName} filter-bar more filters button clicked.`)
  },
  accountConnectionUpdated: (tokenId: number) => track('Connection updated', { tokenId }),
  accountConnectionDeleted: (tokenId: number) => track('Connection deleted', { tokenId }),
  accountConnectionReAuthenticated: (name: string, sourceType: string) =>
    track('Connection re-authenticated successfully', { name, sourceType }),
  assignmentListSorted: (direction: string) => track('Assignments list sorted', { direction }),
  workspaceConnectionAdded: ({ teamId, tokenId, tokenName }: any) =>
    track('Connection successfully added to team', { teamId, tokenId, tokenName }),
  workspaceConnectionFailed: ({ teamId, tokenId, tokenName }: any) =>
    track('Connection add fails', { teamId, tokenId, tokenName }),
  workspaceConnectionDeleted: ({ teamId, tokenId }: any) =>
    track('Connection successfully removed from team', { teamId, tokenId }),
  integrationConnectionAdded: (args: any) => track('Connection added successfully', args),
  integrationConnectionFailed: (args: any) => track('Connection add fails', args),
  connectionAdded: (args: any) => track('Connection added successfully', args),
  connectionFailed: (args: any) => track('Connection add fails', args),
  slackRemoved: () => track('Slack integration removed'),
  slackAdded: () => track('Slack integration added'),
  subscriptionPageViewed: () => track('Subscription page viewed', { ...getHubspotUserToken() }),
  paymentStarted: (plan: string, data: any) => track('Payment started', { plan, ...data }),
  paymentSucceeded: (plan: string, data: any) => track('Payment success', { plan, ...data }),
  paymentFailed: (plan: string, error: any) => track('Payment error', { plan, error }),
  paymentStep: ({ plan, step, data }: any) => track('Payment step', { plan, step, ...data }),
  paymentPortalClosed: () => track('Payment portal closed'),
  paymentManagePortalClosed: () => track('Payment manage portal closed'),
  navbarEvent: (event: string, title: string) => track(event, { title }),
  loginProcessStarted: (args: any) => track('Started login process', args),
  loginOrSignupFailed: (err: any) =>
    track('Login/Signup error', {
      message: `Auth0 login/signup error | ${err.code}: ${err.description}`,
    }),
  sendingMagicLinkFailed: (message: string) => track('Cannot send magic link', { message }),
  magicLinkSent: (email: string) => track('Magic link sent', { email }),
  passwordResetStarted: (email: string) => track('Started reset password process', { email }),
  sendingResetEmailFailed: (message: string) => track('Cannot send reset email', { message }),
  passwordResetSucceeded: (email: string) => track('Reset password success', { email }),
  filterChanged: ({ editModal, isPublic, name }: any) =>
    track('Filter ' + (editModal ? 'updated' : 'added'), { isPublic, name }),
  filterCloned: (filterId: number, name: string, isPublic: boolean) =>
    track('Filter cloned', { filterId, isPublic, name }),
  filterDeleted: (filterId: number, isPublic: boolean) => track('Filter deleted', { isPublic, filterId }),
  goalUpdated: (goal: number, goalType: string) => track('Goal updated', { goal, goalType }),
  shortcutUsed: (title: string) => track('Shortcut used', { title }),
  startSignup: () => track('Start Signup'),
  signedUp: (email: string, method: string) => track('Signed up', { email, method }),
  accountDetailsViewed: (email: string) => track('Account Details Viewed', { email }),
  accountDataAdd: (source: 'demo' | 'helpdesk') => track('Add Account Data', { source }),
  userRemoved: ({ removerId, removerEmail, removedUsers }: any) =>
    track('User removed', { removerId, removerEmail, removedUsers }),
  setupPageFinished: (userId: number, firstHearing: string) => track('Finished setup page', { userId, firstHearing }),
  ratingCategoryAdded: (name: string, teamId: number) => track('Rating category successfully added', { name, teamId }),
  ratingCategoryUpdated: ({ name, categoryId, teamId }: any) =>
    track('Rating category successfully updated', { name, categoryId, teamId }),
  ratingCategoryClicked: ({ categoryId, rating }: any) => track('Clicked rating category', { categoryId, rating }),
  newTeamAdded: ({ teamName, teamId }: any) => track('New team added', { teamName, teamId }),
  reviewUpdated: () => track('Review updated', { addedRatings: true, addedComment: false, addedTags: false }),
  reviewMarkedAsRead: (type: string) => track('Review Marked as Read', { type }),
  teamSelectionClicked: (teamId: number) => track('Clicked team selection', { title: 'Switch team', teamId }),
  signUpClicked: (label: string) => track('Clicked Sign up button', { label }),
  termsOfServiceClicked: () => track('Clicked Terms of Service button'),
  privacyPolicyClicked: () => track('Clicked Privacy Policy button'),
  logInClicked: () => track('Clicked Log in button'),
  magicLinkLoginClicked: () => track('Clicked Log in via Magic Link button'),
  sendMagicLinkClicked: () => track('Clicked Send magic link button'),
  resetPasswordLinkClicked: () => track('Clicked Reset password link'),
  resetPasswordButtonClicked: () => track('Clicked Reset password button'),
  closeMessageClicked: () => track('Clicked message close button', {}),
  ticketListSorted: (sortOption: string) => track('Sorted ticket list', { sortOption, newNavEnabled: true }),
  pinCreated: ({ referenceType, referenceValue, body }: PinModalState) => {
    const query = new URLSearchParams(referenceValue)
    track('Pin created', { type: referenceType, dashboardCard: query.get('cardId'), hasComment: !!body })
  },
  pinEdited: ({ type, value }: PinReference) => {
    const query = new URLSearchParams(value)
    track('Pin edited', { type, dashboardCard: query.get('cardId') })
  },
  pinDeleted: ({ type, value }: PinReference) => {
    const query = new URLSearchParams(value)
    track('Pin deleted', { type, dashboardCard: query.get('cardId') })
  },
  pinCommentCreated: (isReply: boolean, isPinCreator: boolean) =>
    track('Pin comment created', { isReply, isPinCreator }),
  pinCommentEdited: (isReply: boolean, isPinCreator: boolean) => track('Pin comment edited', { isReply, isPinCreator }),
  pinCommentDeleted: (isReply: boolean, isPinCreator: boolean) =>
    track('Pin comment deleted', { isReply, isPinCreator }),
  pinFolderListSorted: (direction: string) => track('Pin folder list sorted', { direction }),
  pinsSearched: () => track('Pins searched'),
  pinParticipantsAdded: ({
    count,
    addedVia,
    isPinCreator,
  }: {
    count: number
    addedVia: 'new_pin' | 'new_comment'
    isPinCreator: boolean
  }) => track('Pin participants added', { count, addedVia, isPinCreator }),
  pinParticipantRemoved: () => track('Pin participant removed'),
  pinsLeftSidebarClicked: (type: 'message' | 'folder') => track('Pin left sidebar clicked', { type }),
  pinClicked: (isPinCreator: boolean) => track('Pin clicked', { isPinCreator }),
  pinsShowSimilar: (type: PinReference_Type, dashboardCard?: boolean) =>
    track('Pins: show similar', { type, dashboardCard }),
  pinReferencePreviewClicked: (type: PinReference_Type) => track('Pin reference preview clicked', { type }),
  visualFiltersPeriodSelected: (period: StatisticalDistributionPeriod) =>
    track('Visual filters period selected', { period }),
  sunburstClicked: ({ type, category }: { type: string; category: string }) =>
    track('Sunburst clicked', { type, category }),
  zoomOutOneLevel: () => track('Sunburst zoom out one level'),
  sunburstCategoryDragged: (category: string) => track('Sunburst category dragged', { category }),
  sunburstCategoryTurnedOff: (category: string) => track('Sunburst category turned off', { category }),
  visualFiltersOpened: () => track('Visual filters opened'),
  quizListClicked: (quizId: string) => track('Quiz list: opened', { quizId }),
  quizListEdited: (quizId: string) => track('Quiz list: edited', { quizId }),
  quizListDuplicated: () => track('Quiz list: duplicated'),
  quizListDeleted: () => track('Quiz list: deleted'),
  quizListLinkCopied: () => track('Quiz list: link copied'),
  quizListArchived: () => track('Quiz list: archived'),
  quizListUnarchived: () => track('Quiz list: unarchived'),
  quizSaved: () => track('Quiz: draft saved'),
  quizPublished: () => track('Quiz: published'),
  quizDuplicated: () => track('Quiz: duplicated'),
  quizDeleted: () => track('Quiz: deleted'),
  quizLinkCopied: () => track('Quiz: link copied'),
  quizAnswered: () => track('Quiz: answered'),
  quizArchived: () => track('Quiz: archived'),
  quizUnarchived: () => track('Quiz: unarchived'),
  coachingOpenCoacheePicker: (source: string) => track('Coaching: open coachee picker', { source }),
  coachingCancelCoacheePicker: () => track('Coaching: cancel coachee picker'),
  coachingCreateThread: () => track('Coaching: create session thread'),
  coachingSortThreads: (field: string) => track('Coaching: sort session threads', { field }),
  coachingFilterThreads: () => track('Coaching: filter session threads'),
  coachingOpenThread: () => track('Coaching: open session thread'),
  coachingRemoveThread: () => track('Coaching: remove session thread'),
  coachingFilterSuggestedUsers: () => track('Coaching: filter suggested users'),
  coachingSortSuggestedUsers: (field: string) => track('Coaching: sort suggested users', { field }),
  coachingPickSuggestedUser: () => track('Coaching: pick suggested user'),
  coachingNewSession: () => track('Coaching: new session'),
  coachingExpandSession: () => track('Coaching: expand session'),
  coachingCopySessionLink: () => track('Coaching: copy session link'),
  coachingStartRenameSession: () => track('Coaching: start rename session'),
  coachingRenamedSession: () => track('Coaching: renamed session'),
  coachingRemoveSession: () => track('Coaching: remove session'),
  coachingScheduleSession: () => track('Coaching: schedule session'),
  coachingEditTalkingPoints: () => track('Coaching: edit session talking points'),
  coachingAddActionItem: () => track('Coaching: add action item'),
  coachingEditActionItem: () => track('Coaching: edit action item'),
  coachingRemoveActionItem: () => track('Coaching: remove action item'),
  coachingMarkActionItemDone: () => track('Coaching: mark action item done'),
  coachingMarkActionItemUndone: () => track('Coaching: mark action item undone'),
  coachingEditPrivateNote: () => track('Coaching: edit private note'),
  coachingOpenSidebarTab: (tab: 'metrics' | 'feedback' | 'pins') => track('Coaching: open sidebar tab', { tab }),
  coachingOpenMetricsLink: (type: 'iqs' | 'dashboard' | 'dashboard_category' | 'quiz' | 'csat') =>
    track('Coaching: open sidebar metrics link', { type }),
  coachingOpenFeedbackConversation: () => track('Coaching: open feedback conversation'),
  coachingOpenPin: (source: 'tab' | 'session') => track('Coaching: open pin', { source }),
  coachingAddPin: () => track('Coaching: add pin to session'),
  coachingRemovePin: () => track('Coaching: remove pin from session'),
  csatDashboardOpened: () => track('CSAT dashboard: opened'),
  csatDashboardCsatOverTimeTimeStepChanged: (timeStep: FilterTimeStep) =>
    track(`CSAT dashboard: CSAT over time time step changed to ${timeStep}`),
  csatDashboardCsatOverTimeElementVisibilityChanged: (element: string, visible: boolean) =>
    track(`CSAT dashboard: CSAT over time ${element} visibility changed to ${visible}`),
  csatDashboardCsatTrendsByBreakdownSourceChanged: (source: string) =>
    track(`CSAT dashboard: CSAT trends by breakdown source changed to ${source}`),
  csatDashboardCsatTrendsByElementVisibilityChanged: (element: string, visible: boolean) =>
    track(`CSAT dashboard: CSAT trends by ${element} visibility changed to ${visible}`),
  csatDashboardCsatByUsersSortChanged: (field: string, direction: string) =>
    track(`CSAT dashboard: CSAT by users sorted by: ${field}, ${direction}`),
  csatDashboardTagsSourceTypeChanged: (sourceType: string) =>
    track(`CSAT dashboard: Tags source type changed to: ${sourceType}`),
  csatDashboardWordCloudWordClicked: () => track('CSAT dashboard: Word cloud word clicked'),
  csatDashboardCsatByBreakdownChanged: (source: string) =>
    track(`CSAT dashboard: CSAT by breakdown source change to: ${source}`),
  csatDashboardCsatBySortChanged: (field: string, direction: string) =>
    track(`CSAT dashboard: CSAT by sorted by: ${field}, ${direction}`),
  csatDashboardSurveyRepliesSortChanged: (field: string, direction: string) =>
    track(`CSAT dashboard: Survey replies sorted by: ${field}, ${direction}`),
  csatDashboardSurveyRepliesConversationOpened: () => track('CSAT dashboard: Survey replies conversation opened'),
  csatDashboardSurveyRepliesPageSizeChanged: () => track('CSAT dashboard: Survey replies page size changed'),
  csatDashboardSurveyRepliesPageChanged: () => track('CSAT dashboard: Survey replies page changed'),
  csatDashboardTimePeriodFilterChanged: () => track('CSAT dashboard: Time period filter changed'),
  csatDashboardWorkspaceFilterChanged: () => track('CSAT dashboard: Workspace filter changed'),
  csatDashboardUserGroupFilterChanged: () => track('CSAT dashboard: User group filter changed'),
  csatDashboardUserFilterChanged: () => track('CSAT dashboard: User filter changed'),
  csatDashboardQuestionTypeChanged: () => track('CSAT dashboard: Question type filter changed'),
  csatDashboardSurveyFilterChanged: () => track('CSAT dashboard: Survey filter changed'),
  csatDashboardScoreFilterChanged: () => track('CSAT dashboard: Score filter changed'),
  csatDashboardSidebarButtonClicked: () => track('CSAT dashboard: More filters button clicked'),
  csatDashboardConversationSourceChanged: () => track('CSAT dashboard: Conversation source filter changed'),
  csatDashboardTicketTagsChanged: () => track('CSAT dashboard: Help desk tags filter changed'),
  csatDashboardWordCloudWordsChanged: () => track('CSAT dashboard: Word cloud words filter changed'),
  csatDashboardPredictedDimensionsChanged: () => track('CSAT dashboard: Comment size filter changed'),
  csatDashboardPredictedTagsChanged: () => track('CSAT dashboard: Predicted CSAT drivers filter changed'),
  csatDashboardCsatReasonsChanged: () => track('CSAT dashboard: CSAT reasons filter changed'),
  csatDashboardCsatLanguagesChanged: () => track('CSAT dashboard: CSAT languages filter changed'),
  csatDashboardViewSettingsOpened: () => track('CSAT dashboard: View settings opened'),
  csatSidebarOpened: () => track('CSAT sidebar: opened'),
  csatConversationClicked: () => track('CSAT dashboard: open conversation'),
  csatTargetChanged: () => track('CSAT dashboard: target changed'),
  csatTargetRemoved: () => track('CSAT dashboard: target removed'),
  csatOwnerSettingChanged: (setting: 'agentWithMostReplies' | 'ticketAssignee') =>
    track(`CSAT dashboard: CSAT owner changed to ${setting}`),
  displayDateSettingChanged: (setting: 'ticketCreated' | 'csatReply') =>
    track(`CSAT dashboard: display date setting changed to ${setting}`),
  // disputes
  disputeStartReview: () => track('Dispute started: review'),
  disputeStartReviewee: () => track('Dispute started: reviewee'),
  disputeStartEdit: () => track('Dispute started: edit'),
  disputeStartApprove: () => track('Dispute started: approve'),
  disputeStartReject: () => track('Dispute started: reject'),
  disputeDiscard: () => track('Dispute discarded'),
  disputeDelete: () => track('Dispute deleted'),
  disputeSubmit: ({ count, ratingsChanged }: { count: number; ratingsChanged: boolean }) =>
    track('Dispute submit: new', { count, ratingsChanged }),
  disputeSubmitEdit: () => track('Dispute submit: edit'),
  disputeSubmitApproveReview: () => track('Dispute submit: approve review'),
  disputeSubmitPartiallyApproveReview: () => track('Dispute submit: partially approve review'),
  disputeSubmitApproveReviewee: () => track('Dispute submit: approve reviewee'),
  disputeSubmitRejectReview: () => track('Dispute submit: reject review'),
  disputeSubmitRejectReviewee: () => track('Dispute submit: reject reviewee'),
  disputeViewed: () => track('Dispute viewed'),
  disputeListExpanded: () => track('Dispute list: expanded'),
  disputeListCollapsed: () => track('Dispute list: collapsed'),
  disputeListAll: () => track('Dispute list: load all'),
  disputeListOpen: () => track('Dispute list: load open'),
  disputeListAuthor: () => track('Dispute list: load created by me'),
  disputeListAssignee: () => track('Dispute list: load assigned to me'),
  disputeDashboardOpened: () => track('Dispute dashboard: opened'),
  dashboardFilterChanged: (route: string, params: any) => track('Changed dashboard filter', { route, params }),
  dashboardExport: () => track('Dashboard export'),
  visitConversationsInDemo: () => track('Visit conversations in DEMO'),
}
