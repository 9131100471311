import config from '@/config'
import { session } from './composables/useSession'

declare global {
  interface Window {
    __z2SunCoWidgetRef: any
    Z2SunCoWidget: any
  }
}

export function loadZ2SunCoWidget() {
  if (!session.features.z2SunCoWidgetDev) return

  const nonce = '__CSP_NONCE__'
  const isStaging = config.flagManager

  if (!isStaging) return

  // Create the script element
  const widgetScript = document.createElement('script')
  widgetScript.setAttribute('nonce', nonce)

  // Configure the script's `onload` behavior
  widgetScript.onload = function () {
    console.info('[Z2SunCoWidget] | widgetScript - LOADING...')

    window.__z2SunCoWidgetRef = new window.Z2SunCoWidget({
      inProduct: true,
      resetAuth: true,
      jwtUrl: 'https://it-jwt-generator-us.zendesk-staging.com/it-jwt-legacy/legacy-sunco',
      position: {
        top: 'auto',
        right: '1rem',
        bottom: '1rem',
        left: 'auto',
      },
      orientation: 'right',
      openWidgetToNewConversation: false,
    })

    console.info('[Z2SunCoWidget] | widgetScript - DONE')
  }

  // Set the script source
  widgetScript.async = true
  widgetScript.setAttribute('src', 'https://static-staging.zdassets.com/z2-sunco-widget/z2-sunco-widget.js')

  widgetScript.onerror = () => console.info('[Z2SunCoWidget] | ERROR while loading the script')

  // Append the script to the document head
  document.head.appendChild(widgetScript)
}
