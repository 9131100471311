import { computed } from 'vue'
import { theme } from '@klausapp/ui-kit'

export default function useNavbarRoutes() {
  const navbarRoutesMap = computed<Record<string, { label: string; icon: any }>>(() => ({
    dashboard: {
      label: 'modules.application.navbar.dashboard',
      icon: theme.icons.dashboard,
    },
    conversations: {
      label: 'modules.application.navbar.conversations',
      icon: theme.icons.conversations,
    },
    activity: {
      label: 'modules.application.navbar.activity',
      icon: theme.icons.activity,
    },
    'coaching.sessions': {
      label: 'modules.application.navbar.coaching',
      icon: theme.icons.coaching,
    },
    settings: {
      label: 'modules.application.navbar.settings_submenu.settings',
      icon: theme.icons.settings,
    },
    tasks: {
      label: 'modules.application.navbar.tasks',
      icon: theme.icons.tasks,
    },
    users: {
      label: 'modules.application.navbar.settings_submenu.users_bots_workspaces',
      icon: theme.icons.users,
    },
  }))

  return { navbarRoutesMap }
}
