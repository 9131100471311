import { sample } from 'lodash-es'
import qs from 'query-string'

import type { UserIdsRequest, UsersInviteResponse } from '@klausapp/services/users'
import type { AccountRole, WorkspaceRole } from '@/data/roles'
import { api } from '@/api'
import { defaultTeamColors } from '@/utils/colors'
import type { AccountUser, WorkspaceUser, ExternalUser } from './types'
import { isUserReadonly } from './utils'

export const getUserCount = async () => {
  const { count } = await api.get('users/count').json<{ count: string }>()
  return parseInt(count, 10)
}

export const calculateAvailableSeats = (emails?: string[]) =>
  api
    .post('users/calculate-available-seats', { json: { emails } })
    .json<{ seatsAvailable: number; newSeatsUsed: number }>()

export const createWorkspace = async (name: string, addAllConnections: boolean) => {
  const workspace = { name, color: sample(defaultTeamColors), addAllConnections }
  const { id } = await api.post('workspaces', { json: workspace }).json<{ id: string }>()
  return { ...workspace, id: parseInt(id) }
}

export const getAccountUsers = () => api.get('users').json<{ readonly: boolean; users: AccountUser[] }>()

export const getAccountUsersCSV = () => api.get('users.csv').json<string>()

export const getUnconnectedExternalUsers = async () => {
  const { users } = await api.get('external-users/unconnected').json<any>()

  return users.map((u: any) => {
    const groupedUsers = u.groupedUsers.sort(
      (a: any, b: any) => new Date(a.connectionCreated).getTime() - new Date(b.connectionCreated).getTime(),
    )
    const name = groupedUsers.find((gu: any) => !!gu.name).name
    const avatar = groupedUsers.find((gu: any) => !!gu.avatar)?.avatar

    return { ...u, name, avatar }
  }) as ExternalUser[]
}

export const getWorkspaceUsers = (workspaceId: number) =>
  api
    .get('users', { headers: { 'X-Klaus-Workspace': workspaceId.toString() } })
    .json<{ readonly: boolean; users: WorkspaceUser[] }>()

interface BulkUpdateUserPayload {
  workspaceId?: number
  userIds: string[]
  role?: string
  workspacesToAdd?: number[]
  workspacesToRemove?: number[]
  groupsToAdd?: string[]
  groupsToRemove?: string[]
}

export interface BulkCreateUserPayload {
  name: string
  email: string
  workspace: number | string
  accountRole?: AccountRole
  workspaceRole?: WorkspaceRole
}
interface BulkUpdateWorkspaceMembershipPayload {
  workspaceId: number
  userIds: string[]
  action: 'add' | 'remove'
  workspaceRoles?: { userId: string; role: WorkspaceRole }[]
}

export const bulkUpdateUser = (json: BulkUpdateUserPayload) => api.put('users', { json }).json<any>()

export const bulkCreateUser = async (payload: BulkCreateUserPayload[]) => {
  const { users } = await api.post('users', { json: { users: payload } }).json<any>()
  return users as AccountUser[]
}

export const bulkInviteUser = async (payload: BulkCreateUserPayload[]) => {
  const { users } = await api.post('users/invite', { json: { users: payload } }).json<any>()
  return users as AccountUser[]
}

export const bulkReinviteUsers = async (payload: UserIdsRequest) =>
  await api.post('users/resend-invites', { json: payload }).json<UsersInviteResponse>()

export const bulkRemoveUser = (userIds: string[]) =>
  api.delete('users', { searchParams: qs.stringify({ userIds }) }).json<any>()

export const bulkUpdateWorkspaceMembership = ({
  workspaceId,
  userIds,
  action,
  workspaceRoles,
}: BulkUpdateWorkspaceMembershipPayload) =>
  api
    .put('users', {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
      json: { userIds, [action === 'add' ? 'workspacesToAdd' : 'workspacesToRemove']: [workspaceId], workspaceRoles },
    })
    .json<any>()

export const getUsersNotInWorkspace = async (workspaceId: number) => {
  const { users } = await api
    .get('users/not-in-workspace', { headers: { 'X-Klaus-Workspace': workspaceId.toString() } })
    .json<any>()
  return users
    .sort((a: any, b: any) => a.name.localeCompare(b.name))
    .filter((u: any) => !isUserReadonly(u)) as WorkspaceUser[]
}

export const setUserAsBot = (userId: string, reviewable: boolean) =>
  api.post(`users/${userId}/set-as-bot`, { json: { reviewable } }).json()
