import { computed, ref } from 'vue'
import { type MenuState } from './types'

export default function useMenu(): MenuState {
  const activeItemIndex = ref(-1)
  const itemIds = ref<string[]>([])
  const itemIdToSelect = ref<string | null>(null)

  const isEmpty = computed(() => itemIds.value.length === 0)

  const focusPrevious = () => {
    if (isEmpty.value) return

    if (activeItemIndex.value === 0) {
      activeItemIndex.value = itemIds.value.length - 1
    } else {
      activeItemIndex.value--
    }
  }

  const focusNext = () => {
    if (isEmpty.value) return

    if (activeItemIndex.value === itemIds.value.length - 1) {
      activeItemIndex.value = 0
    } else {
      activeItemIndex.value++
    }
  }

  const activeItemId = computed(() => itemIds.value[activeItemIndex.value])

  const resetScroll = () => {
    activeItemIndex.value = -1
  }

  const setItemIds = (ids: string[]) => {
    if (ids.length !== itemIds.value.length) {
      resetScroll()
    }
    itemIds.value = ids
  }

  const selectFocusedItem = () => {
    if (activeItemId.value) {
      itemIdToSelect.value = activeItemId.value
    }
  }

  const resetSelectedItem = () => {
    itemIdToSelect.value = null
  }

  return {
    focusPrevious,
    focusNext,
    activeItemId,
    resetScroll,
    setItemIds,
    selectFocusedItem,
    itemIdToSelect,
    resetSelectedItem,
  }
}
