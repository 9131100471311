import { computed, type Ref } from 'vue'
import { createEventHook } from '@vueuse/core'

import { SelectAllModes, type PropsWithDefaults, type UpdateParams, type OptionGroup, type Modes } from '../../types'
import { ALL_ID } from '../../constants'

import { getOptionGroups, getGroupOptions } from '../utils'
import useMultipleAllList from './useMultipleAllList'
import useMultipleTagsList from './useMultipleTagsList'
import useMultipleNoneList from './useMultipleNoneList'
import useMultipleIntermediaryList from './useMultipleIntermediaryList'

const handlerByMode = {
  [SelectAllModes.All]: useMultipleAllList,
  [SelectAllModes.Tags]: useMultipleTagsList,
  [SelectAllModes.None]: useMultipleNoneList,
  [SelectAllModes.IntermediaryAll]: useMultipleIntermediaryList,
}

export default function useMultipleList<
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(props: PropsWithDefaults<T, P, L, M, V, O>, emit: any, searchQuery: Ref<string>) {
  const updateResults = createEventHook<UpdateParams<T, P, M, O>>()

  const handler = handlerByMode[props.selectAllMode]

  const {
    excludedValues,
    isSelected,
    isIndeterminate,
    reorderList,
    selectedIds,
    showAllGroup,
    sortedOptions,
    sortedGroups,
    toggle,
    onUpdate,
    addOrSelectOption,
  } = handler<T, P, L, M, V, O>(props, emit, searchQuery)

  onUpdate(updateResults.trigger)

  const getAllGroup = (): OptionGroup<T> => ({
    id: '1',
    options: [
      {
        [props.propToCheck]: ALL_ID,
        [props.label]: props.selectAllLabel,
      } as T, // TODO: fix this
    ],
    total: 1,
    name: undefined,
  })

  const allGroups = computed(() => {
    const groups = sortedOptions.value ? getOptionGroups(sortedOptions.value, props.total) : sortedGroups.value
    return [...(showAllGroup.value ? [getAllGroup()] : []), ...(groups || [])]
  })

  const allOptions = computed(() => getGroupOptions<T>(allGroups.value))

  const selectedOptions = computed(() => allOptions.value.filter((o) => isSelected(o)))

  return {
    allGroups,
    excludedValues,
    isSelected,
    isIndeterminate,
    reorderList,
    selectedIds,
    selectedOptions,
    allOptions,
    toggle,
    addOrSelectOption,
    onUpdate: updateResults.on,
  }
}
