import { computed, reactive, ref, watch } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { useRoute, useRouter } from 'vue-router'
import { sortBy } from 'lodash-es'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import type { StateChanger } from 'v3-infinite-loading'
import {
  AssignmentV2_GetUserAssignmentConversationsResponse_ConversationStatus as Status,
  type AssignmentV2_GetUserAssignmentConversationsResponse_TicketListItem as AssignmentTaskItem,
  type AssignmentV2_UserAssignment,
} from '@klausapp/services/assignments_v2'

import analytics from '@/utils/analytics'
import { bus } from '@/utils/bus'
import { getCalibrationSessions as apiGetCalibrationSessions, type CalibrationSession } from '@/api/calibration'
import { session } from '@/composables/useSession'
import useReviewSidebar from '@/composables/useReviewSidebar'
import useTicketRouteParams from '@/composables/useTicketRouteParams'
import { getAssignmentConversations, getAssignments } from '@/modules/tasks/api'
import { analyticsLabels, type SortValues } from '@/modules/tasks/utils/assignmentOptions'
import { defaultState, type State } from '@/modules/tasks/types'
import { RootViews } from '@/types/pageLayout'
import { hasWorkspaceRole, hasAccountRole } from '@/utils/roleUtils'
import { getCalibrationConversations } from '@/modules/shared/TicketContent/api/calibration'
import type { TicketListItemExt } from '@/modules/conversations/types'
import useTasksList, { ListType } from '@/modules/tasks/composables/useTasksList'
import { setLayoutState } from './usePageLayout'

interface StoragePayload {
  conversationId: string
  connectionId: string | number
  allowedReviewees?: string[]
}

dayjs.extend(isToday)

export const TASKS_STORAGE_KEY = 'klausExtensionAssignments:v2'
// Keeping reviewees in a separate storage to avoid conflicts with old assignments; these can be consolidated in the future
export const TASKS_REVIEWEES_STORAGE_KEY = 'klausExtensionAssignmentsReviewees:v1'

export default createSharedComposable(() => {
  const route = useRoute()
  const router = useRouter()
  const { state: sidebarState } = useReviewSidebar()
  const { connectionId, conversationId, assignmentId, calibrationSessionId } = useTicketRouteParams()
  const { state: listState, isAssignmentsList, isCalibrationsList } = useTasksList()

  const state = reactive<State>(defaultState)

  const lazyLoadingKey = computed(
    () =>
      `${isAssignmentsList.value ? assignmentId.value : calibrationSessionId.value}-${
        state.listParams.sort
      }-${state.listParams.status}`,
  )
  const previousTicket = computed(() => getTicketByIndex(-1))
  const nextTicket = computed(() => getTicketByIndex(1))
  const statusesWithoutActions = [Status.REMOVED, Status.DONE]

  const isAssignmentTasksView = computed(() =>
    Boolean(route.name?.toString().startsWith('tasks.assignments') && !!assignmentId.value),
  )
  const canCreateAssignmentReview = computed(() => {
    if (!conversationId.value || !listState.items.length) return false

    const conversation = (listState.items as AssignmentTaskItem[]).find(
      (conv) => conv.externalId === conversationId.value,
    )

    if (statusesWithoutActions.includes(conversation?.ticketStatus?.status)) return false

    if (state.activeAssignment?.settings?.goal?.config?.assigned)
      return conversation?.assignedReviewer?.id === session.user.id.toString()

    return true
  })

  const currFinished = ref<AssignmentV2_UserAssignment[]>([])
  const excludeCurrFinished = (a: AssignmentV2_UserAssignment) => !currFinished.value.includes(a)

  const activeAssignmentCycles = computed(() => {
    const allAssignments = [
      ...currFinished.value,
      ...(state.allAssignments?.filter((a) => a.reviewCount !== a.goal).filter(excludeCurrFinished) || []),
    ]

    return sortBy(allAssignments, 'cycleEnd')
  })

  const completedAssignmentCycles = computed(
    () => state.allAssignments?.filter((a) => !a.goal || a.reviewCount === a.goal).filter(excludeCurrFinished) || [],
  )

  const canSeeCalibrations = computed(() => {
    const hasRequiredRole =
      hasWorkspaceRole('MANAGER', 'LEAD', 'REVIEWER') ||
      (!session.workspace?.role && hasAccountRole('ADMIN', 'MANAGER'))
    const featureEnabled = session.features.calibrationPro
    const settingEnabled = session.workspace.settings.calibrationEnabled

    return hasRequiredRole && featureEnabled && settingEnabled
  })
  const calibrationSessionDetails = computed(
    () =>
      state.allCalibrationSessions?.find(({ sessionId }) => sessionId.toString() === calibrationSessionId.value) || {
        title: '',
        dueAt: '',
        createdBy: null,
        createdAt: '',
        updatedBy: null,
        updatedAt: '',
      } /* default session */,
  )
  const activeSessions = computed(() =>
    state.allCalibrationSessions?.filter(
      (session) => dayjs(session.dueAt).isAfter(dayjs(), 'day') || dayjs(session.dueAt).isToday(),
    ),
  )
  const pastSessions = computed(() =>
    state.allCalibrationSessions?.filter((session) => dayjs(session.dueAt).isBefore(dayjs(), 'day')),
  )

  function getConversationRoute({ params, query }) {
    const ticketParams = {
      connectionId: params.connectionId,
      conversationId: params.conversationId,
      ...(params.messageId ? { messageId: encodeURIComponent(params.messageId) } : {}),
    }

    let name = isAssignmentsList.value ? 'tasks.assignments.review' : 'tasks.calibrations.review'
    if (route.name?.toString().startsWith('extension')) name = 'extension.tasks'

    return {
      name,
      params: {
        ...ticketParams,
        ...(isCalibrationsList.value
          ? { calibrationSessionId: encodeURIComponent(params.calibrationSessionId) }
          : { assignmentId: encodeURIComponent(params.assignmentId) }),
      },
      query,
    }
  }

  function navigateToTicket(ticket: AssignmentTaskItem | TicketListItemExt, evt?: KeyboardEvent | MouseEvent) {
    if (ticket) {
      router.push(
        getConversationRoute({
          params: {
            connectionId: ticket.paymentTokenId,
            conversationId: ticket.externalId,
            ...(isCalibrationsList.value
              ? { calibrationSessionId: calibrationSessionId.value }
              : { assignmentId: assignmentId.value }),
          },
          query: route.query,
        }),
      )
      if (evt) analytics.shortcutUsed('key' in evt ? evt.key : 'click')
    }
  }

  function redirectToConversation() {
    const isCorrectRoute =
      ((isAssignmentsList.value && state.activeAssignment?.id === assignmentId.value) ||
        (isCalibrationsList.value && state.activeCalibrationSession?.sessionId === calibrationSessionId.value)) &&
      conversationId.value === listState.items[0]?.externalId &&
      connectionId.value === Number(listState.items[0]?.paymentTokenId)

    if (isCorrectRoute && listState.items.length) return

    if (isAssignmentsList.value) {
      state.activeAssignment = assignmentId.value
        ? state.allAssignments?.find((assignment) => assignment?.id === assignmentId.value)
        : state.allAssignments?.[0]
    } else if (isCalibrationsList.value) {
      state.activeCalibrationSession = calibrationSessionId.value
        ? state.allCalibrationSessions?.find((session) => session?.sessionId === calibrationSessionId.value)
        : state.allCalibrationSessions?.[0]
    }

    const currentlyActive = isAssignmentsList.value ? state.activeAssignment : state.activeCalibrationSession
    if (!currentlyActive || !listState.items.length) {
      listState.isLoading = false
      sidebarState.loadingFeedback = false
      return
    }

    const firstTicketRoute = getConversationRoute({
      params: {
        ...(isAssignmentsList.value
          ? { assignmentId: state.activeAssignment?.id }
          : { calibrationSessionId: state.activeCalibrationSession?.sessionId }),
        connectionId: connectionId.value || Number(listState.items[0]?.paymentTokenId) || undefined,
        conversationId: conversationId.value || listState.items[0]?.externalId || undefined,
      },
      query: route.query,
    })

    if (firstTicketRoute) router.replace(firstTicketRoute)
  }

  function getTicketByIndex(indexModifier: number) {
    if (!listState.items.length) return

    const index =
      listState.items.findIndex(
        (t) => t.externalId === conversationId.value && t.paymentTokenId === connectionId.value?.toString(),
      ) + indexModifier

    return index !== -1 && listState.items[index]
  }

  function navigateToPreviousTicket(evt: KeyboardEvent | MouseEvent) {
    navigateToTicket(previousTicket.value, evt)
  }

  function navigateToNextTicket(evt?: KeyboardEvent | MouseEvent) {
    navigateToTicket(nextTicket.value, evt)
  }

  async function _getConversations() {
    const { conversations, total, seed } = await getAssignmentConversations(
      assignmentId.value || state.activeAssignment.id,
      state.listParams,
    )

    listState.items = (conversations as AssignmentTaskItem[]) || []
    listState.total = String(total)
    state.listParams.seed = seed
    sidebarState.loadingFeedback = false
  }

  async function sortAssignments(sortValue: SortValues) {
    state.listParams.sort = sortValue
    state.listParams.page = 0

    await _getConversations()
    analytics.assignmentListSorted(analyticsLabels[sortValue])
  }

  function setLoadingState(setTicketAsLoading?: boolean) {
    listState.isLoading = true
    if (setTicketAsLoading) bus.$emit('ticket-show-loader')
    sidebarState.loadingFeedback = true
  }

  async function getAllAssignments(setActive = false) {
    setLoadingState(setActive)

    const res = await getAssignments()

    state.allAssignments = sortBy(res.assignments, 'cycleEnd') || []

    if (!res.assignments.length) {
      listState.isLoading = false
      sidebarState.loadingFeedback = false
      return
    }
    if (setActive && assignmentId.value) setActiveAssignment(assignmentId.value)
    else state.activeAssignment = state.allAssignments[0]
  }

  function setActiveAssignment(assignmentId: string) {
    const newAssignment = state.allAssignments?.find((a) => a.id === assignmentId)

    if (newAssignment) state.activeAssignment = newAssignment
  }

  async function getAssignmentConversationsList(setTicketAsLoading = false) {
    state.listParams.page = 0

    if (!listState.isLoading) setLoadingState(setTicketAsLoading)

    if (setTicketAsLoading) state.changedListItem = []

    await _getConversations()

    listState.isLoading = false
    sidebarState.loadingFeedback = false
  }

  function _setActiveCalibrationSession(calibSessionId: string) {
    const newSession = state.allCalibrationSessions?.find((a) => a.sessionId === calibSessionId)

    if (newSession) state.activeCalibrationSession = newSession
  }

  async function getCalibrationSessions(calibSessionId?: string): Promise<CalibrationSession[]> {
    if (!canSeeCalibrations.value) return []

    const { data } = await apiGetCalibrationSessions(session.workspace.id)

    state.allCalibrationSessions = data

    if (calibSessionId) _setActiveCalibrationSession(calibSessionId)
    else state.activeCalibrationSession = data[0]
  }

  async function getCalibrationConversationList(setTicketAsLoading = true) {
    if (!listState.isLoading) setLoadingState(setTicketAsLoading)

    const { tickets } = await getCalibrationConversations({ sessionId: calibrationSessionId.value })

    listState.items = tickets || []
    listState.total = String(tickets.length)
    listState.isLoading = false
    sidebarState.loadingFeedback = false
  }

  function loadNextPage($loader: StateChanger) {
    state.infiniteLoader = $loader
    state.listParams.page += 1
  }

  function getDefaultReviewee(activeConversation: AssignmentTaskItem, assigneeId: number) {
    const { assignedReviewees, assignedBotReviewees } = activeConversation

    if (assignedReviewees?.length) return Number(assignedReviewees[0].id)
    if (assignedBotReviewees?.length) return assignedBotReviewees[0].id

    return assigneeId
  }

  function setDefaultReviewee() {
    if (!conversationId.value || !listState.items?.length) return

    const activeConversation = (listState.items as AssignmentTaskItem[])?.find(
      (item) => item.externalId === conversationId.value,
    )
    const assigneeId =
      sidebarState.ticket?.assignee?.externalId === activeConversation?.assigneeId
        ? Number(sidebarState.ticket?.assignee?.internalId)
        : null

    if (activeConversation) {
      sidebarState.defaultReviewee = getDefaultReviewee(activeConversation, assigneeId)

      if (route.name?.toString().startsWith('tasks.calibrations')) {
        sidebarState.assignmentRevieweeIds = []
        return
      }

      sidebarState.assignmentRevieweeIds = [
        assigneeId || null,
        ...(activeConversation.assignedReviewees.length
          ? activeConversation.assignedReviewees.map((r) => Number(r.id))
          : []),
      ].filter((i) => i)
    }
  }

  function _getStorageKey(connectionId: number | string, conversationId: string) {
    return [session.user.id, session.account.id, session.workspace.id, connectionId, conversationId].join('.')
  }

  function persistAssignment({ conversationId, connectionId, allowedReviewees }: StoragePayload) {
    const savedAssignments = JSON.parse(sessionStorage[TASKS_STORAGE_KEY] || '{}')
    const savedReviewees = JSON.parse(sessionStorage[TASKS_REVIEWEES_STORAGE_KEY] || '{}')

    savedAssignments[_getStorageKey(connectionId, conversationId)] = assignmentId.value
    savedReviewees[assignmentId.value] = allowedReviewees

    sessionStorage[TASKS_STORAGE_KEY] = JSON.stringify(savedAssignments)
    sessionStorage[TASKS_REVIEWEES_STORAGE_KEY] = JSON.stringify(savedReviewees)
  }

  function removePersistedAssignment() {
    if (!sessionStorage[TASKS_STORAGE_KEY]) return

    const savedAssignments = JSON.parse(sessionStorage[TASKS_STORAGE_KEY])
    const key = _getStorageKey(connectionId.value, conversationId.value)

    if (key in savedAssignments) {
      delete savedAssignments[key]
      sessionStorage[key] = JSON.stringify(savedAssignments)
    }
  }

  watch(
    () => state.listParams.page,
    async () => {
      if (
        state.listParams.page === 0 ||
        !isAssignmentsList.value ||
        (!route.name?.toString().startsWith('tasks') && !route.name?.toString().startsWith('extension.tasks'))
      )
        return

      const res = await getAssignmentConversations(assignmentId.value, state.listParams)

      if (!res.conversations.length) {
        state.infiniteLoader?.complete()
        return
      }

      state.infiniteLoader?.loaded()

      listState.items = [...listState.items, ...res.conversations] as AssignmentTaskItem[]
      listState.total = String(res.total)
      state.listParams.seed = res.seed
      sidebarState.loadingFeedback = false
    },
  )

  watch(
    () => [listState.items],
    () => {
      if (!route.name?.toString().startsWith('tasks')) return

      if (!(listState.isLoading && listState.items?.length)) return bus.$emit('ticket-hide-loader')

      if (listState.items?.length) setDefaultReviewee()

      bus.$emit('ticket-hide-loader')
    },
    { immediate: true, deep: true },
  )

  watch(
    () => sidebarState.ticket?.assignee,
    () => route.name?.toString().startsWith('tasks') && setDefaultReviewee(),
  )

  watch(
    [() => route?.name, () => assignmentId.value, () => calibrationSessionId.value],
    async ([newName, newAssId, newCalibSessionId], [oldName, oldAssId, oldCalibSessionId]) => {
      if (!route.name?.toString().startsWith('tasks.')) return
      if (oldName === newName && newCalibSessionId && oldCalibSessionId === newCalibSessionId) return
      if (oldName === newName && newAssId && oldAssId === newAssId) return

      state.changedListItem = []

      listState.type = newName.toString().startsWith('tasks.assignments') ? ListType.Assignment : ListType.Calibration

      setLayoutState(RootViews.Tasks, {
        leftSidebar: 'list',
        rightSidebar: undefined,
      })

      if (isAssignmentsList.value) {
        state.activeCalibrationSession = undefined
        currFinished.value = []

        if (!assignmentId.value) {
          await getAllAssignments()

          if (!state.activeAssignment?.id) {
            return router.push({
              name: 'tasks.calibrations',
            })
          }

          return router.replace({
            name: 'tasks.assignments',
            params: { assignmentId: state.activeAssignment.id },
          })
        }

        if (assignmentId.value !== state.activeAssignment?.id) {
          setActiveAssignment(assignmentId.value)
        }
      }

      if (isCalibrationsList.value) {
        state.activeAssignment = undefined

        if (!calibrationSessionId.value) {
          await getCalibrationSessions()
          return router.replace({
            name: 'tasks.calibrations',
            params: { calibrationSessionId: state.activeCalibrationSession?.sessionId },
          })
        }

        await getCalibrationSessions(calibrationSessionId.value)
        await getCalibrationConversationList()
        redirectToConversation()
      }
    },
    { immediate: true },
  )

  return {
    /* all */
    state,
    isAssignmentTasksView,
    canCreateAssignmentReview,
    lazyLoadingKey,
    loadNextPage,
    previousTicket,
    nextTicket,
    navigateToNextTicket,
    navigateToPreviousTicket,
    redirectToConversation,
    currFinished,
    /* assignments */
    activeAssignmentCycles,
    completedAssignmentCycles,
    sortAssignments,
    getAllAssignments,
    getAssignmentConversationsList,
    statusesWithoutActions,
    setActiveAssignment,
    /* extension */
    persistAssignment,
    removePersistedAssignment,
    /* calibrations */
    activeSessions,
    pastSessions,
    canSeeCalibrations,
    calibrationSessionDetails,
    getCalibrationSessions,
    getCalibrationConversationList,
  }
})
