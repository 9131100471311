import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex gap-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pin_modal_cta = _resolveComponent("pin-modal-cta")!
  const _component_folder_picker = _resolveComponent("folder-picker")!
  const _component_comment_editor = _resolveComponent("comment-editor")!
  const _component_pin_reference_preview = _resolveComponent("pin-reference-preview")!
  const _component_how_pins_work = _resolveComponent("how-pins-work")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_q_modal = _resolveComponent("q-modal")!

  return (_openBlock(), _createBlock(_component_q_modal, {
    show: _ctx.pinModalData.visible,
    title: _ctx.title,
    class: _normalizeClass(_ctx.$style.modal),
    onClose: _ctx.close
  }, {
    actions: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.actions)
      }, [
        _createVNode(_component_how_pins_work, { class: "shrink-0" }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ui_button, {
            secondary: "",
            onClick: _ctx.close
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('universal.cancel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]),
          _createVNode(_component_ui_button, {
            "data-testid": "pin-save",
            disabled: _ctx.savingDisabled,
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.saveButtonText), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "onClick"])
        ])
      ], 2 /* CLASS */)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_pin_modal_cta),
      _createVNode(_component_folder_picker, {
        class: _normalizeClass(_ctx.$style.select),
        "selected-user-id": _ctx.pinModalData.folderUserId,
        "account-users": _ctx.accountUsers,
        onInput: _ctx.userSelected
      }, null, 8 /* PROPS */, ["class", "selected-user-id", "account-users", "onInput"]),
      (_ctx.pinModalData.visible)
        ? (_openBlock(), _createBlock(_component_comment_editor, {
            key: 0,
            ref: "commentEditorRef",
            modelValue: _ctx.pinModalData.body,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pinModalData.body) = $event)),
            "data-testid": "pin-modal-editor",
            class: _normalizeClass(_ctx.$style.commentEditor),
            placeholder: _ctx.editing ? '' : _ctx.$t('pins.modal.add_others_with'),
            "team-members": _ctx.accountUsers,
            tall: "",
            "taglist-always-empty": "",
            "template-type": _ctx.TemplateType.Pin,
            onTags: _ctx.setTags,
            onSubmit: _ctx.save
          }, null, 8 /* PROPS */, ["modelValue", "class", "placeholder", "team-members", "template-type", "onTags", "onSubmit"]))
        : _createCommentVNode("v-if", true),
      (_ctx.showPreview)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style.pinReferenceContainer)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.divider)
            }, null, 2 /* CLASS */),
            _createVNode(_component_pin_reference_preview, {
              "reference-type": _ctx.pinModalData.referenceType,
              "reference-value": _ctx.pinModalData.referenceValue
            }, null, 8 /* PROPS */, ["reference-type", "reference-value"])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["show", "title", "class", "onClose"]))
}