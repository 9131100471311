import { loadConfig } from '@klausapp/ui-server/config'

const config = loadConfig(({ envString, envBoolean }) => ({
  // General
  gitCommit: envString('GIT_COMMIT', ''),
  devtools: envBoolean('DEVTOOLS', false), // Turn on debugging/profiling mode for app
  flagManager: envBoolean('FLAG_MANAGER', false),
  apiOverride: envString('API_OVERRIDE', ''),
  analyticsId: envString('ANALYTICS_ID', ''),
  pusherKey: envString('PUSHER_KEY', ''),
  giphyApiKey: envString('GIPHY_API_KEY', ''),
  birdieKey: envString('BIRDIE_KEY', ''),
  cookieBotKey: envString('COOKIE_BOT_KEY', ''),
  clientRegion: envString('CLIENT_REGION', 'ZZ'),
  baseUrl: envString('BASE_URL', ''),
  lookerDomain: envString('LOOKER_DOMAIN'),
  zendeskDomain: envString('ZENDESK_DOMAIN'),
  embeddedRuntime: false,
  checkA11y: envBoolean('CHECK_A11Y', false),
  domainRoot: envString('DOMAIN_ROOT', ''),

  // Sentry
  sentryDsn: envString('SENTRY_DSN', ''),
  sentryEnv: envString('SENTRY_ENV', ''),

  // Auth
  authClientId: envString('AUTH_CLIENT_ID'),
  authDomain: envString('AUTH_DOMAIN'),
  authLogoutUrl: envString('AUTH_LOGOUT_URL'),
  authSsoRedirects: envString('AUTH_SSO_REDIRECTS'),
  authWhitelistedExtensions: envString('AUTH_WHITELISTED_EXTENSIONS'),

  // Integrations
  aircallIdentifier: envString('AIRCALL_IDENTIFIER'),
  helpscoutIdentifier: envString('HELPSCOUT_IDENTIFIER'),
  integrationCallbackUrl: envString('INTEGRATION_CALLBACK_URL'),
  slackId: envString('SLACK_ID'),
  slackCallbackUrl: envString('SLACK_CALLBACK_URL'),
  driftIdentifier: envString('DRIFT_IDENTIFIER'),
  intercomIdentifier: envString('INTERCOM_IDENTIFIER'),
  livechatIdentifier: envString('LIVECHAT_IDENTIFIER'),
  salesforceIdentifier: envString('SALESFORCE_IDENTIFIER'),
  zendeskIdentifier: envString('ZENDESK_IDENTIFIER'),
  frontIdentifier: envString('FRONT_IDENTIFIER'),
  gorgiasIdentifier: envString('GORGIAS_IDENTIFIER'),
}))

export default config

export const embeddedRuntime = () => config.embeddedRuntime && !('Cypress' in window)

export const hasZendeskProxy = config.baseUrl === '/qa'
