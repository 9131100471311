import { session } from '@/composables/useSession'

export const saveToSessionStorage = (key: string, value: string | number) => {
  const storageKey = `${key}.${getStorageKey()}`
  sessionStorage.setItem(storageKey, JSON.stringify(value))
}

export const getFromSessionStorage = (key: string, defaultValue?: string | number) => {
  const storageKey = `${key}.${getStorageKey()}`
  const storedValue = sessionStorage.getItem(storageKey)
  return storedValue ? JSON.parse(storedValue) : defaultValue
}

function getStorageKey() {
  return [session.user.id, session.account.id, session.workspace.id].join('.')
}
