import { computed, type Ref } from 'vue'
import { createEventHook } from '@vueuse/core'

import { type PropsWithDefaults, type UpdateParams, Modes } from '../../types'
import { getOptionGroups, getGroupOptions } from '../utils'
import useSortedList from '../common/useSortedList'
import useSelectedList from '../common/useSelectedList'

export default function useSingleList<
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(props: PropsWithDefaults<T, P, L, M, V, O>, _emit: any, _searchQuery: Ref<string>) {
  const updateResults = createEventHook<UpdateParams<T, P, M, O>>()

  const { reorderList, sortedOptions, sortedGroups } = useSortedList(props)
  const { selectedValues, excludedValues, updateModelValue, onUpdate } = useSelectedList<T, P, L, M, V, O>(props)

  onUpdate(updateResults.trigger)

  const isSelected = (option: T) => {
    const valueToCheck = props.isObject
      ? (props.modelValue as T[]).map((item) => item[props.propToCheck])
      : (props.modelValue as T[P][])

    return valueToCheck.includes(option[props.propToCheck])
  }

  const toggle = (option: T) => {
    selectedValues.value = [option[props.propToCheck]]

    updateModelValue(selectedValues.value, [], Modes.Single)
  }

  const selectedIds = computed(() => props.modelValue)

  const allGroups = computed(() => {
    if (sortedOptions.value) {
      return getOptionGroups(sortedOptions.value, props.total)
    }

    return sortedGroups.value || []
  })

  const allOptions = computed(() => getGroupOptions(allGroups.value))

  const selectedOptions = computed(() => allOptions.value.filter(isSelected))

  return {
    allGroups,
    isSelected,
    isIndeterminate: (_option: T) => false,
    reorderList,
    selectedIds,
    selectedOptions,
    allOptions,
    toggle,
    excludedValues,
    addOrSelectOption: (_query: string) => undefined,
    onUpdate: updateResults.on,
  }
}
